import React, { useEffect, useState } from "react";
import "./ProfilePopover.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useNavigate, Link } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import { Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ProfilePic from "../../shared/ProfilePic";
import InfoModel from "../../shared/simpleModel/InfoModel";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import LogoutModal from "../LogoutModal/LogoutModal";
import { getProfile } from "../../configration/HandleCookies";

const ProfilePopover = () => {
  const [profileInformation, setprofileInformation] = useState(null);
  const [initialProfile, setInitialProfile] = useState(false);
  const [LogoutInfoModel, setLogoutInfoModel] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate();

  const userDetailss = window.sessionStorage.getItem("userDetails");
  const user = JSON.parse(userDetailss);

  useEffect(() => {
    if (user.userType === "PATIENT") {
      AxiosInstance.get("profile/get")
        .then((response) => {
          if (response == null) {
            // const userDetails = JSON.parse(getProfile());
            const userDetails = window.sessionStorage.getItem("userDetails");
            setprofileInformation(JSON.parse(userDetails));
            setInitialProfile(true);
          } else {
            setprofileInformation(response);
          }
          let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
          setUserDetails(userDetails);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const userDetails = window.sessionStorage.getItem("userDetails");
      setprofileInformation(JSON.parse(userDetails));
      setUserDetails(user);
      setInitialProfile(true);
    }
  }, []);

  const logOutUser = () => {
    setLogoutInfoModel((prev) => !prev);
  };

  console.log("user in profile", user, userDetails, profileInformation);

  return (
    <div className="ProfilePopover">
      <div className="ProfileContainer">
        <div className="profile-small">
          <ProfilePic
            profileInformation={profileInformation}
            initialProfile={initialProfile}
          />
        </div>
        <div className="profileName">
          <span className="profileDetails">
            {initialProfile == true
              ? `${profileInformation?.firstName} ` +
                " " +
                `${profileInformation?.lastName}`
              : `${profileInformation?.profile?.firstName} ` +
                " " +
                `${profileInformation?.profile?.lastName}`}
          </span>
          {userDetails &&
            userDetails?.userType != "TESTLAB" &&
            userDetails?.userType != "DOCTOR" &&
            userDetails?.userType != "DIETITIAN" &&
            userDetails?.userType != "PHYSICIAN" &&
            userDetails?.userType != "ADMIN" &&
            userDetails?.userType != "CORPORATE" &&
            userDetails?.userType != "GYM" &&
            userDetails?.userType != "TRAINER" &&
            userDetails?.userType != "PSYCHIATRIST" && (
              <div className="ProfileContainer">
                <span>Gen: </span>{" "}
                <span className="profileDetails">
                  {profileInformation?.profile?.generation
                    ? profileInformation?.profile?.generation
                    : "----"}
                </span>
              </div>
            )}
        </div>
      </div>
      <div className="ProfileContainer email">
        <MailIcon />
        <span className="profileDetails">
          {initialProfile == true
            ? `${profileInformation?.email} `
            : `${profileInformation?.profile?.email} `}
        </span>
      </div>
      <div className="ProfileContainer">
        <PhoneIcon />
        <span className="profileDetails">
          {initialProfile == true
            ? profileInformation?.phoneNumber
            : profileInformation?.profile?.contactNumber}
        </span>
      </div>
      {userDetails &&
        userDetails?.userType != "TESTLAB" &&
        userDetails?.userType != "DOCTOR" &&
        userDetails?.userType != "DIETITIAN" &&
        userDetails?.userType != "PHYSICIAN" &&
        userDetails?.userType != "ADMIN" &&
        userDetails?.userType != "CORPORATE" &&
        userDetails?.userType != "GYM" &&
        userDetails?.userType != "TRAINER" &&
        userDetails?.userType != "PSYCHIATRIST" && (
          <>
            <div className="ProfileContainer">
              <span>UHID :</span>{" "}
              <span className="profileDetails">
                {profileInformation?.upId ? profileInformation?.upId : "----"}
              </span>
            </div>
            <div className="Profile-links" style={{ marginTop: "18px" }}>
              <Link className="profileDetails" to="/profile-register">
                Profile
              </Link>
              <Button className="profileDetails" onClick={logOutUser}>
                Logout
              </Button>
            </div>
          </>
        )}
      <InfoModel
        open={LogoutInfoModel}
        setOpen={setLogoutInfoModel}
        heading="Confirm Logout"
        headerIcon={
          <ErrorRoundedIcon style={{ color: "#d80000", fontSize: "40px" }} />
        }
      >
        <div className="popup-description">
          <LogoutModal onClose={logOutUser}></LogoutModal>
        </div>
      </InfoModel>
    </div>
  );
};

export default ProfilePopover;
