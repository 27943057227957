import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import PaymentByWhatsappNotification from "./configration/PaymentByWhatsappNotification";
import BuySubscription from "./screens/AddToCart/BuySubscription";
import { ChangePassword } from "./screens/ChangePassword/ChangePassword";
import Dashboard from "./screens/Dashboard/Dashboard";
import { ElementCaseHistory } from "./screens/ElementCaseHistory/ElementCaseHistory";
import { ElementChronologyOfLabTests } from "./screens/ElementChronologyOfLabTests";
import { ElementLabTestRecords } from "./screens/ElementLabTestRecords";
import { ElementOverview } from "./screens/ElementOverview/ElementOverview";
import { ForgotPassword } from "./screens/ForgotPassword/ForgotPassword";
import { ForgotPasswordVerificationScreen } from "./screens/ForgotPasswordVerificationScreen";
import { HelpCenter } from "./screens/HelpCenter/HelpCenter";
import { HomePageAfterLogin } from "./screens/HomePageAfterLogin/HomePageAfterLogin";
import { HomePageBeforeLogin } from "./screens/HomePageBeforeLogin/HomePageBeforeLogin";
import { Landing } from "./screens/Landing";
import { LoginEmail } from "./screens/LoginEmail";
import { LoginOtp } from "./screens/LoginOtp";
import { MedicalHistory } from "./screens/MedicalHistory/MedicalHistory";
import { Menu } from "./screens/Menu/Menu";
import PatientMigrationWrapper from "./screens/PatientMigration/PatientMigrationWrapper";
import { SignUp } from "./screens/SignUp/SignUp";
import { SubscriptionPageAfterLogin } from "./screens/SubscriptionPageAfterLogin/SubscriptionPageAfterLogin";
import { TrendsToWatch } from "./screens/TrendsToWatch";
import { VerificationScreen } from "./screens/VerificationScreen";
import Faqs from "./screens/layouts/Faqs";
import Maintenance from "./screens/layouts/Maintenance";
import PageNotFound from "./screens/layouts/PageNotFound";
import PrivateRoutes from "./screens/layouts/PrivateRoutes";
import ProtectedRoutes from "./screens/layouts/ProtectedRoutes";
import Profile_Registration_Form from "./screens/profileRegistration/Profile_Registration_Form";
import DoctorsHomeScreen from "./screens/DoctorsScreen/home";
import GeneralInfo from "./screens/LabScreens/genral_Info_page";
import LabPrice from "./screens/LabScreens/labprice";
import LabTestInfo from "./screens/LabScreens/labtestInfo";
import LabScreen from "./screens/LabScreens/labScreen";
import MyOrders from "./screens/LabScreens/myOrders";
import Invoice from "./screens/LabScreens/invoice";
import Billing from "./screens/LabScreens/billing";
import Performance from "./screens/LabScreens/performance";
import LabLoginEmail from "./screens/LabScreens/labSignin";
// import RiskAssessment from "./screens/RiskAssessment/RiskAssessment";
import LifeStyleRiskAssessment from "./screens/LifeStyleRiskAssessment/LifeStyleRiskAssessment";
import LifeStyleRiskAssessmentSurvey from "./screens/LifeStyleRiskAssessment/LifeStyleRiskAssessmentSurveyForm";
import WeeklyLog from "./screens/WeeklyLog/WeeklyLog";
import RiskTools from "./screens/RiskTools/RiskTools";
import PreventiveCare from "./screens/PreventiveCare/PreventiveCare";
import HealthOutlook from "./screens/HealthOutlook/HealthOutlook";
import ChronologyOfEvents from "./screens/ChronologyOfEvents/ChronologyOfEvents";
import HealthCardReport from "./screens/HealthCardReport/HealthCardReport";
import RiskTracker from "./screens/RiskTracker/RiskTracker";
import SuccessComponent from "./screens/SuccessComponent/SuccessComponent";
import Vaccinations from "./screens/Vaccination/Vaccinations";
import SubscriptionPackages from "./screens/SubscriptionPackages/SubscriptionPackages";
import VaccinationServices from "./screens/Vaccination/VaccinationServices";
import { CartProvider } from "./screens/TestCart/CartContext";
import RoleSelectionPage from "./screens/LoginEmail/role_selection_page";
import DoctorLogin from "./screens/DoctorsScreen/doctorLogin";
import DieticiansLogin from "./screens/Dieticians/dieticians-login";
import ResearchLogin from "./screens/Researchers/researchs-login";
import LifeStyleModifications from "./screens/LifeStyleModifications/LifeStyleModifications";
import BrowserCheck from "./BrowserCheck";
import DieticianHomeScreen from "./screens/Dieticians/dietician-home-screen";
import DieticianGeneralInfo from "./screens/Dieticians/dietician-generalInfo";
import DieticianPrice from "./screens/Dieticians/dieticianPrice";
import DieticianTestInfo from "./screens/Dieticians/dietician-test-info";
import DieticianOrders from "./screens/Dieticians/DieticiansOrders";
import DieticianBilling from "./screens/Dieticians/dietician-billing";
import DieticianInvoice from "./screens/Dieticians/dietician-invoice";
import DieticianPerformance from "./screens/Dieticians/dietician-performance";
import AdminLogin from "./screens/AdminScreens/AdminLogin";
import AdminHomeScreen from "./screens/AdminScreens/AdminHomeScreen.";
import AdminFormsPage from "./screens/AdminScreens/AdminFormsPage";
import CorporateOrders from "./screens/AdminScreens/CorporateOrders";
import ApprovalsInvoice from "./screens/AdminScreens/ApprovalsInvoice";
import CreatePartners from "./screens/AdminScreens/CreatePartners";
import PatientsTab from "./screens/DoctorsScreen/PatientsTab";
import DoctorOrders from "./screens/DoctorsScreen/DoctorOrders";
import CMEDoctorsInformation from "./screens/CME_Doctors_Information/CME_Doctors_Information";
import OnlineConsultation from "./screens/DoctorsScreen/OnlineConsultation";
import DoctorProfile from "./screens/DoctorsScreen/DoctorProfile";
import PatientOnlineConsultation from "./screens/PatientOnlineConsultation/PatientOnlineConsultation";
import { DoctorLoginWithOtp } from "./screens/DoctorsScreen/DoctorLoginWithOtp";
import { DoctorVerficationScreen } from "./screens/DoctorsScreen/DoctorVerficationScreen";
import DoctorOrderHistory from "./screens/DoctorsScreen/DoctorOrderHistory";
import DoctorBilling from "./screens/DoctorsScreen/DoctorBilling";
import DoctorPerformance from "./screens/DoctorsScreen/DoctorPerformance";
import DieticianOnlineConsultation from "./screens/Dieticians/DieticianOnlineConsultation";
import DietitianOrderHistory from "./screens/Dieticians/DietitianOrderHistory";
import CorporateLogin from "./screens/CorporateScreens/Corporate-login";
import CorporateDashboard from "./screens/CorporateScreens/CorporateDashboard";
import CorporateGeneralInformation from "./screens/CorporateScreens/CorporateGeneralInformation";
import CorporateSubscriptionPlans from "./screens/CorporateScreens/CorporateSubscriptionPlans";
import CorporateEmployeesList from "./screens/CorporateScreens/CorporateEmployeesList";
import CorporateHealthPlans from "./screens/CorporateScreens/CorporateHealthPlans";
import CorporateInvoice from "./screens/CorporateScreens/CorporateInvoice";
import CorporateWallet from "./screens/CorporateScreens/CorporateWallet";
import DietitianManageSchedule from "./screens/Dieticians/DietitianManageSchedule";
import PhysiotherapistLogin from "./screens/PhysiotherapistScreens/PhysiotherapistLogin";
import PhysiotherapistProfile from "./screens/PhysiotherapistScreens/PhysiotherapistProfile";
import PhysiotherapistPriceList from "./screens/PhysiotherapistScreens/PhysiotherapistPriceList";
import PhysiotherapistPackageInformation from "./screens/PhysiotherapistScreens/PhysiotherapistPackageInformation";
import PhysiotherapistOrders from "./screens/PhysiotherapistScreens/PhysiotherapistOrders";
import PhysiotherapistOrderHistory from "./screens/PhysiotherapistScreens/PhysiotherapistOrderHistory";
import PhysiotherapistBilling from "./screens/PhysiotherapistScreens/PhysiotherapistBilling";
import PhysiotherapistInvoice from "./screens/PhysiotherapistScreens/PhysiotherapistInvoice";
import PhysiotherapistOnlineConsultation from "./screens/PhysiotherapistScreens/PhysiotherapistOnlineConsultation";
import PhysiotherapistPerformance from "./screens/PhysiotherapistScreens/PhysiotherapistPerformance";
import GymSampleScreen from "./screens/GymScreen/GymSampleScreen";
import GymLogin from "./screens/GymScreen/GymLogin";
import GymHomePage from "./screens/GymScreen/GymHomePage";
import GymGeneralInfo from "./screens/GymScreen/GymGeneralInfo";
import FitnessTrainingScreen from "./components/FitnessTraining/fitnessTrainingScreen";
import Meeting from "./screens/PatientOnlineConsultation/ChimeMetting/Meeting";
import FitnessTrainerGeneralInfo from "./components/FitnessTraining/fitnessTrainerGeneralInfo";
import TrainerLogin from "./components/FitnessTraining/TrainerLogin";
import TrainerHomePage from "./components/FitnessTraining/TrainerHomePage";
import LabBankDetail from "./screens/LabScreens/LabBankDetail";
import LabOrderHistory from "./screens/LabScreens/LabOrderHistory";
import GymOrders from "./screens/GymScreen/GymOrders";
import AdminApprovedUnApproved from "./screens/AdminScreens/AdminApprovedUnApproved";
import FitnnessTrainingOrders from "./components/FitnessTraining/FitnnessTrainingOrders";
import WellnessSurvey from "./screens/WellnessServices/WellnessSurvey";
import WellnessServices from "./screens/WellnessServices/WellnessServices";
import GymBilling from "./screens/GymScreen/GymBilling";
import TrainerBilling from "./components/FitnessTraining/TrainerBilling";
import TrainerCalendar from "./components/FitnessTraining/TrainerManageSchedule";
import PsychiatristLogin from "./screens/Psychiatrist/PsychiatristLogin";
import PsychiatristHome from "./screens/Psychiatrist/PsychiatristHome";
import PhysiotherapistManageSchedule from "./screens/PhysiotherapistScreens/PhysiotherapistManageSchedule";
import PsychiatristGeneralInformation from "./screens/Psychiatrist/PsychiatristGeneralInformation";
import PsychiatristManageSchedule from "./screens/Psychiatrist/PsychiatristManageSchedule";
import PsychiatristOrder from "./screens/Psychiatrist/PsychiatristOrder";
import PsychiatristOrderHistory from "./screens/Psychiatrist/PsychiatristOrderHistory";
import PsychiatristOnlineConsultation from "./screens/Psychiatrist/PsychiatristOnlineConsultation";
import PsychiatristBilling from "./screens/Psychiatrist/PsychiatristBilling";

export const App = () => {
  const [hasVisited, setHasVisited] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const visitedCookie = Cookies.get("visited");
    if (!visitedCookie) {
      Cookies.set("visited", "true", { expires: 365 });
      setHasVisited(false);
    } else {
      setHasVisited(true);
    }

    // Check if user is not logged in and store initial URL
    const token = window.sessionStorage.getItem("token");
    const surveyPattern = /^\/survey\/\d+$/;
    if (!token && surveyPattern.test(location.pathname)) {
      sessionStorage.setItem("initialURL", location.pathname + location.search);
      navigate("/homeBeforeLogin");
    }
  }, [location, navigate]);

  useEffect(() => {
    const Cme_Pattern = /\/cme-login-otp/;
    if (Cme_Pattern.test(location.pathname)) {
      sessionStorage.setItem(
        "cme_doctors",
        location.pathname + location.search
      );
    }
  }, []);

  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;
  if (isUnderMaintenance == "true") {
    return <Maintenance />;
  }

  const appUrl = window.location.href;

  if (showMessage) {
    return (
      <BrowserCheck
        url={appUrl}
        onBrowserCheck={(isUnsupported) => setShowMessage(isUnsupported)}
      />
    );
  }

  return (
    <CartProvider>
      <BrowserCheck
        url={appUrl}
        onBrowserCheck={(isUnsupported) => setShowMessage(isUnsupported)}
      />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="health-outlook"
            element={<HealthOutlook></HealthOutlook>}
          ></Route>
          <Route
            path="/overview"
            element={<ElementOverview></ElementOverview>}
          ></Route>
          <Route
            path="/trends-to-watch"
            element={<TrendsToWatch></TrendsToWatch>}
          ></Route>
          <Route
            path="/events"
            element={<ChronologyOfEvents></ChronologyOfEvents>}
          ></Route>
          <Route path="/weekly-log" element={<WeeklyLog></WeeklyLog>}></Route>
          <Route path="/menu" element={<Menu />} />
          <Route path="/home" element={<HomePageAfterLogin />} />
          <Route path="/health-report-card" element={<HealthCardReport />} />
          <Route path="/success" element={<SuccessComponent />} />
          <Route path="/risk-tracker" element={<RiskTracker />} />
          <Route
            path="/subscription"
            element={<SubscriptionPageAfterLogin />}
          />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/case-history" element={<ElementCaseHistory />} />
          <Route
            path="/patient-online-consultation"
            element={<PatientOnlineConsultation />}
          />
          <Route
            path="/lab-history"
            element={<ElementChronologyOfLabTests />}
          />
          <Route
            path="/wellness-services"
            element={<WellnessServices></WellnessServices>}
          ></Route>
          <Route
            path="/profile-register"
            element={<Profile_Registration_Form />}
          />
          <Route path="/lab-test-records" element={<ElementLabTestRecords />} />
          <Route path="/risk-tools" element={<RiskTools></RiskTools>}></Route>
          <Route
            path="/preventive-care"
            element={<PreventiveCare></PreventiveCare>}
          ></Route>
          <Route
            path="/lifestyle-modifications"
            element={<LifeStyleModifications></LifeStyleModifications>}
          ></Route>
          <Route
            path="/survey/:id"
            element={
              <LifeStyleRiskAssessmentSurvey></LifeStyleRiskAssessmentSurvey>
            }
          ></Route>
          <Route
            path="/risk-assessment"
            element={<LifeStyleRiskAssessment></LifeStyleRiskAssessment>}
          />
          <Route path="/vaccinations" element={<Vaccinations />} />
          <Route path="/vaccine-reports" element={<VaccinationServices />} />
          <Route
            path="/subscription-packages"
            element={<SubscriptionPackages></SubscriptionPackages>}
          ></Route>
          {/* <Route
          path="/cardiac-risk-assessment"
          element={<CardiacRiskAssessment></CardiacRiskAssessment>}
        />
        <Route
          path="/risk-assessment/diabetes-calculator"
          element={<DiabetesCalculator />}
        />
        <Route
          path="/kidney-risk-assessment"
          element={<KidneyRiskAssessment></KidneyRiskAssessment>}
        /> */}
          <Route path="/medical-history" element={<MedicalHistory />} />
          <Route path="/my-patients" element={<PatientsTab />} />
          <Route path="/doctor-home" element={<DoctorsHomeScreen />} />
          <Route path="/doctor-Order" element={<DoctorOrders />} />
          <Route path="/doctor-order-histoy" element={<DoctorOrderHistory />} />
          <Route path="/online-consultation" element={<OnlineConsultation />} />
          <Route path="/doctor-billing" element={<DoctorBilling />} />
          <Route path="/doctor-performance" element={<DoctorPerformance />} />
          <Route path="/lab-screen" element={<LabScreen />} />
          <Route path="/lab-general-info" element={<GeneralInfo />} />
          <Route path="/lab-price" element={<LabPrice />} />
          <Route path="/lab-test-info" element={<LabTestInfo />} />
          <Route path="/my-Order" element={<MyOrders />} />
          <Route path="/my-Order-history" element={<LabOrderHistory />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/bank-detail" element={<LabBankDetail />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/gym-general-info" element={<GymGeneralInfo />} />
          <Route path="/gym-orders" element={<GymOrders />}></Route>
          <Route
            path="/fitness-training-screen"
            element={<FitnessTrainingScreen />}
          />
          <Route
            path="/trainer-manage-schedule"
            element={<TrainerCalendar />}
          ></Route>
          <Route path="/trainer-orders" element={<FitnnessTrainingOrders />} />
          <Route path="/trainer-billing" element={<TrainerBilling />}></Route>
          <Route
            path="/trainer-bank-detail"
            element={<LabBankDetail />}
          ></Route>
          <Route
            path="/fitness-trainer-general-info"
            element={<FitnessTrainerGeneralInfo />}
          />
          <Route
            path="/admin-screen"
            element={<AdminHomeScreen></AdminHomeScreen>}
          ></Route>
          <Route
            path="/admin-forms"
            element={<AdminFormsPage></AdminFormsPage>}
          ></Route>
          <Route
            path="/admin-Orders"
            element={<CorporateOrders></CorporateOrders>}
          ></Route>
          <Route
            path="/admin-profile-approval"
            element={<AdminApprovedUnApproved></AdminApprovedUnApproved>}
          ></Route>
          <Route
            path="/approvals"
            element={<ApprovalsInvoice></ApprovalsInvoice>}
          ></Route>
          <Route
            path="/create-partners"
            element={<CreatePartners></CreatePartners>}
          ></Route>
          <Route
            path="/dietician-screen"
            element={<DieticianHomeScreen></DieticianHomeScreen>}
          ></Route>
          <Route
            path="/dietician-general-info"
            element={<DieticianGeneralInfo></DieticianGeneralInfo>}
          ></Route>
          <Route
            path="/dietician-price"
            element={<DieticianPrice></DieticianPrice>}
          ></Route>
          <Route
            path="/dietician-test-info"
            element={<DieticianTestInfo></DieticianTestInfo>}
          ></Route>
          <Route
            path="/dietician-manage-schedule"
            element={<DietitianManageSchedule></DietitianManageSchedule>}
          ></Route>
          <Route
            path="/dietician-Order"
            element={<DieticianOrders></DieticianOrders>}
          ></Route>
          <Route
            path="/dietician-order-history"
            element={<DietitianOrderHistory></DietitianOrderHistory>}
          ></Route>
          <Route
            path="/dietician-online-consultation"
            element={<DieticianOnlineConsultation />}
          />
          <Route
            path="/dietician-bank-detail"
            element={<LabBankDetail />}
          ></Route>
          <Route
            path="/dietician-billing"
            element={<DieticianBilling></DieticianBilling>}
          ></Route>
          <Route
            path="/dietician-invoice"
            element={<DieticianInvoice></DieticianInvoice>}
          ></Route>
          <Route
            path="/dietician-performance"
            element={<DieticianPerformance></DieticianPerformance>}
          ></Route>
          <Route
            path="/physiotherapist-general-info"
            element={<PhysiotherapistProfile></PhysiotherapistProfile>}
          ></Route>
          <Route
            path="/physiotherapist-price"
            element={<PhysiotherapistPriceList></PhysiotherapistPriceList>}
          ></Route>
          <Route
            path="/physiotherapist-test-info"
            element={
              <PhysiotherapistPackageInformation></PhysiotherapistPackageInformation>
            }
          ></Route>
          <Route
            path="/physiotherapist-manage-schedule"
            element={
              <PhysiotherapistManageSchedule></PhysiotherapistManageSchedule>
            }
          ></Route>
          <Route
            path="/physiotherapist-Order"
            element={<PhysiotherapistOrders></PhysiotherapistOrders>}
          ></Route>
          <Route
            path="/physiotherapist-order-history"
            element={
              <PhysiotherapistOrderHistory></PhysiotherapistOrderHistory>
            }
          ></Route>
          <Route
            path="/physiotherapist-online-consultation"
            element={
              <PhysiotherapistOnlineConsultation></PhysiotherapistOnlineConsultation>
            }
          ></Route>
          <Route
            path="/physiotherapist-billing"
            element={<PhysiotherapistBilling></PhysiotherapistBilling>}
          ></Route>
          <Route
            path="/physiotherapist-invoice"
            element={<PhysiotherapistInvoice></PhysiotherapistInvoice>}
          ></Route>
          <Route
            path="/physiotherapist-performance"
            element={<PhysiotherapistPerformance></PhysiotherapistPerformance>}
          ></Route>
          {/* <Route
            path="/psychiatrist-home"
            element={<PsychiatristHome></PsychiatristHome>}
          ></Route> */}
          <Route
            path="/corporate-dashboard"
            element={<CorporateDashboard></CorporateDashboard>}
          ></Route>
          <Route
            path="/corporate-general-info"
            element={
              <CorporateGeneralInformation></CorporateGeneralInformation>
            }
          ></Route>
          <Route
            path="/corporate-subscription-plans"
            element={<CorporateSubscriptionPlans></CorporateSubscriptionPlans>}
          ></Route>
          <Route
            path="/corporate-employees-list"
            element={<CorporateEmployeesList></CorporateEmployeesList>}
          ></Route>
          <Route
            path="/corporate-health-plans"
            element={<CorporateHealthPlans></CorporateHealthPlans>}
          ></Route>
          <Route
            path="/corporate-invoice"
            element={<CorporateInvoice></CorporateInvoice>}
          ></Route>
          <Route
            path="/corporate-wallet"
            element={<CorporateWallet></CorporateWallet>}
          ></Route>
          <Route
            path="/gym-home-screen"
            element={<GymHomePage></GymHomePage>}
          ></Route>
          <Route
            path="/trainer-home-page"
            element={<TrainerHomePage />}
          ></Route>
          <Route
            path="/gym-sample-screen"
            element={<GymSampleScreen></GymSampleScreen>}
          ></Route>
          <Route path="/gym-billing" element={<GymBilling />}></Route>
          <Route path="/gym-bank-detail" element={<LabBankDetail />}></Route>
          <Route
            path="/psychiatrist-home"
            element={<PsychiatristHome></PsychiatristHome>}
          ></Route>
          <Route
            path="/psychiatrist-general-info"
            element={
              <PsychiatristGeneralInformation></PsychiatristGeneralInformation>
            }
          ></Route>
          <Route
            path="/psychiatrist-manage-schedule"
            element={<PsychiatristManageSchedule></PsychiatristManageSchedule>}
          ></Route>
          <Route
            path="/psychiatrist-order"
            element={<PsychiatristOrder></PsychiatristOrder>}
          ></Route>
          <Route
            path="/psychiatrist-order-history"
            element={<PsychiatristOrderHistory></PsychiatristOrderHistory>}
          ></Route>
          <Route
            path="/psychiatrist-online-consultation"
            element={
              <PsychiatristOnlineConsultation></PsychiatristOnlineConsultation>
            }
          ></Route>
          {/* <Route
            path="/psychiatrist-bank-detail"
            element={<PsychiatristBilling></PsychiatristBilling>}
          ></Route> */}
          <Route
            path="/psychiatrist-billing"
            element={<PsychiatristBilling></PsychiatristBilling>}
          ></Route>
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route path="/meeting" element={<Meeting></Meeting>}></Route>

          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login-email" element={<LoginEmail />} />
          <Route
            path="/SubscriptionPageBeforeLogin"
            element={<SubscriptionPackages />}
          />
          <Route path="/cme_doctors" element={<CMEDoctorsInformation />} />
          <Route
            path="/"
            element={!hasVisited ? <Landing /> : <HomePageBeforeLogin />}
          />
          <Route path="/homeBeforeLogin" element={<HomePageBeforeLogin />} />
          <Route
            path="/verification-2/:number"
            element={<VerificationScreen />}
          />
          <Route
            path="/verification-1/:number"
            element={<ForgotPasswordVerificationScreen />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/login-otp" element={<LoginOtp />} />
          <Route path="/cme-login-otp" element={<LoginOtp />} />
          <Route path="/doctor-login-otp" element={<DoctorLoginWithOtp />} />
          <Route
            path="/doctor-verication-otp/:number"
            element={<DoctorVerficationScreen />}
          />
          <Route path="/doctor-profile" element={<DoctorProfile />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/payment_to"
            element={<PaymentByWhatsappNotification />}
          />
          <Route path="/faqs" element={<Faqs />} />
          <Route
            path="/patient-migration"
            element={<PatientMigrationWrapper />}
          />
        </Route>
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/buy-subscription" element={<BuySubscription />} />
        <Route path="/lab-sign-in" element={<LabLoginEmail />} />
        <Route path="/doctor-sign-in" element={<DoctorLogin />} />
        <Route path="/dietician-sign-in" element={<DieticiansLogin />} />
        <Route path="/corporate-sign-in" element={<CorporateLogin />} />
        <Route
          path="/physiotherapist-sign-in"
          element={<PhysiotherapistLogin />}
        />
        <Route path="/psychiatrist-sign-in" element={<PsychiatristLogin />} />
        <Route path="/admin-sign-in" element={<AdminLogin />} />
        <Route path="/research-sign-in" element={<ResearchLogin />} />
        <Route path="/role-page" element={<RoleSelectionPage />} />
        <Route path="/gym-sign-in" element={<GymLogin />} />
        <Route path="/trainer-sign-in" element={<TrainerLogin />} />
        <Route path="/wellness-survey" element={<WellnessSurvey />} />
      </Routes>
    </CartProvider>
  );
};
