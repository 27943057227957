import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GridViewIcon from "@mui/icons-material/GridView";
import MenuIcon from "@mui/icons-material/Menu";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Popover from "@mui/material/Popover";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NotificationModel from "./components/NotificationModel/NotificationModel";
import ProfilePopover from "./components/ProfilePopover/ProfilePopover";
import { AxiosInstance } from "./configration/AxiosConfiguration";
import { strings } from "./constants/strings";
import profileRegister from "./img/profile-register.jpg";
import ProfilePic from "./shared/ProfilePic";
import InfoModel from "./shared/simpleModel/InfoModel";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { MdOutlineShoppingCart } from "react-icons/md";
import { getBuildPackageData } from "./constants/apiList";
import { useCart } from "./screens/TestCart/CartContext";
import { InfoRounded } from "@mui/icons-material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import HomeIcon from "@mui/icons-material/Home";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import "./App.css";
import PeopleIcon from "@mui/icons-material/People";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const patientMenuList = [
  {
    menuName: "Home",
    icon: <DashboardIcon />,
    className: "menu-item",
    path: "/home",
    toolTipId: "home",
  },
  {
    menuName: "Subscription",
    icon: <CalendarMonthOutlinedIcon />,
    className: "menu-item",
    path: "/subscription",
    toolTipId: "subscription",
  },
  {
    menuName: "Profile",
    icon: <PermIdentityIcon />,
    className: "menu-item",
    path: "/profile-register",
    toolTipId: "profile-register",
  },
  {
    menuName: "Menu",
    icon: <MenuIcon />,
    className: "menu-item",
    path: "/menu",
    minWidth: "20px",
    minHeight: "20px",
    toolTipId: "menu",
  },
  {
    menuName: "Dashboard",
    icon: <GridViewIcon />,
    className: "menu-item",
    path: "/dashboard",
    toolTipId: "dashboard",
  },
];

const NavMenuListDoctor = [
  {
    menuName: "Home",
    icon: <GridViewIcon />,
    className: "menu-item",
    path: "/doctor-home",
    toolTipId: "dashboard",
  },
  {
    menuName: "My Patients",
    icon: <GridViewIcon />,
    className: "menu-item",
    path: "/my-patients",
    toolTipId: "dashboard",
  },
];

const NavMenuLabScreen = [
  {
    menuName: "Home",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/lab-screen",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/lab-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Price List",
    icon: <CurrencyRupeeIcon />,
    className: "menu-item",
    path: "/lab-price",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Test Information",
    icon: <MedicalInformationIcon />,
    className: "menu-item",
    path: "/lab-test-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];

const NavMenuCorporateScreen = [
  {
    menuName: "Dashboard",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/corporate-dashboard",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/corporate-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Subscription Plans",
    icon: <MedicalInformationIcon />,
    className: "menu-item",
    path: "/corporate-subscription-plans",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Health Plans",
    icon: <HealthAndSafetyIcon />,
    className: "menu-item",
    path: "/corporate-health-plans",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];

const NavMenuDieticianScreen = [
  {
    menuName: "Home",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/dietician-screen",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/dietician-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Price List",
    icon: <CurrencyRupeeIcon />,
    className: "menu-item",
    path: "/dietician-price",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Package Information",
    icon: <MedicalInformationIcon />,
    className: "menu-item",
    path: "/dietician-test-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Manage Schedule",
    icon: <CalendarMonthIcon />,
    className: "menu-item",
    path: "/dietician-manage-schedule",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];

const NavMenuAdminScreen = [
  {
    menuName: "Home",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/admin-screen",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Forms",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/admin-forms",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Create Partners Login",
    icon: <PeopleIcon />,
    className: "menu-item",
    path: "/create-partners",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];

const NavMenuPhysiotherapistScreen = [
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/physiotherapist-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Price List",
    icon: <CurrencyRupeeIcon />,
    className: "menu-item",
    path: "/physiotherapist-price",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Package Information",
    icon: <MedicalInformationIcon />,
    className: "menu-item",
    path: "/physiotherapist-test-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Manage Schedule",
    icon: <CalendarMonthIcon />,
    className: "menu-item",
    path: "/physiotherapist-manage-schedule",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];
const NavMenuGymScreen = [
  {
    menuName: "Home",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/gym-home-screen",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/gym-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  // {
  //   menuName: "Price List",
  //   icon: <CurrencyRupeeIcon />,
  //   className: "menu-item",
  //   // path: "/physiotherapist-price",
  //   toolTipId: "dashboard",
  //   className: "lab-menu-name",
  // },
];
const NavMenuTrainerScreen = [
  {
    menuName: "Home",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/trainer-home-page",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/fitness-trainer-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Manage Schedule",
    icon: <CalendarMonthIcon />,
    className: "menu-item",
    path: "/trainer-manage-schedule",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];

const NavMenuPsychiatristScreen = [
  {
    menuName: "Home",
    icon: <HomeIcon />,
    className: "menu-item",
    path: "/psychiatrist-home-page",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "General Information",
    icon: <InfoRounded />,
    className: "menu-item",
    path: "/psychiatrist-general-info",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
  {
    menuName: "Manage Schedule",
    icon: <CalendarMonthIcon />,
    className: "menu-item",
    path: "/psychiatrist-manage-schedule",
    toolTipId: "dashboard",
    className: "lab-menu-name",
  },
];

function Header() {
  const { refresh } = useCart();
  const divRef = useRef();
  const divRef2 = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileInformation, setprofileInformation] = useState(null);
  const [initialProfile, setInitialProfile] = useState(false);
  const [NotificationInfoModel, setNotificationInfoModel] = useState(false);
  const [testLabOrderList, setTestLabOrderList] = useState({});
  const [familyMembers, setFamilyMembers] = useState();
  const [profileAge, setProfileAge] = useState();
  const [familyProfile, setFamilyProfile] = useState(null);

  const location = useLocation();

  const NotificationModelPopup = () => {
    setNotificationInfoModel((prev) => !prev);
  };
  const searchParams = new URLSearchParams(location.search);
  const isProfileRegister = searchParams.get("isSignedUp");

  const PopuphandleClick = () => {
    setAnchorEl(divRef2.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // const userDetails = JSON.parse(getProfile());
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));

  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");
  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const userType = userDetails?.userType;
  const NavMenuList =
    userType === "DOCTOR"
      ? NavMenuListDoctor
      : userType === "TESTLAB"
      ? NavMenuLabScreen
      : userType === "DIETITIAN"
      ? NavMenuDieticianScreen
      : userType === "ADMIN"
      ? NavMenuAdminScreen
      : userType === "CORPORATE"
      ? NavMenuCorporateScreen
      : userType === "PHYSICIAN"
      ? NavMenuPhysiotherapistScreen
      : userType === "GYM"
      ? NavMenuGymScreen
      : userType === "TRAINER"
      ? NavMenuTrainerScreen
      : userType === "PSYCHIATRIST"
      ? NavMenuPsychiatristScreen
      : patientMenuList;

  const getBuildPackageDate = async () => {
    await AxiosInstance.get(`${getBuildPackageData}`)
      .then((response) => {
        if (response !== null) {
          setTestLabOrderList(response?.testLabCartList);
        } else {
          setTestLabOrderList("");
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const setRelationshipName = () => {
    AxiosInstance.get("/family/get-family")
      .then((response) => {
        setFamilyMembers(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getFamilyProfile = (event) => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyProfile(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userType === "PATIENT") {
      AxiosInstance.get("profile/get")
        .then((response) => {
          setProfileAge(response?.profile?.age);
          if (response == null) {
            // const userDetails = JSON.parse(getProfile());
            // const userDetails = window.sessionStorage.getItem("userDetails");
            setprofileInformation(userDetails);
            setInitialProfile(true);
          } else {
            setprofileInformation(response);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      getBuildPackageDate();
      setRelationshipName();
      getFamilyProfile();
    } else {
      setprofileInformation(userDetails);
      setInitialProfile(true);
    }
  }, []);

  useEffect(() => {
    if (userType === "PATIENT") {
      getBuildPackageDate();
    }
  }, [refresh]);
  sessionStorage.setItem("email", profileInformation?.email);
  sessionStorage.setItem("phoneNumber", profileInformation?.phoneNumber);
  const [relationship, setRelationship] = useState("");

  const handleChange = (event) => {
    setRelationship(event.target.value);
  };
  function getBaseUrl() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2];
    return newPath;
  }
  const familyMemberLogin = async (item) => {
    const familyRequest = {
      parentToken: token,
      patientId: item.patientId,
    };
    AxiosInstance.post(`/family/member/patientLogin/`, familyRequest)
      .then((res) => {
        const userDetails = encodeURIComponent(JSON.stringify(res));
        sessionStorage.setItem("originalTab", window.location.href);
        const newTab = window.open(
          `${getBaseUrl()}/home?token=${userDetails}&familyLogin=${true}`,
          "_blank"
        );
      })
      .catch((error) => {});
  };

  const handleIconClick = () => {
    const originalTab = sessionStorage.getItem("originalTab");
    //  if (originalTab) {

    //   //  window.close(); // close the new tab
    //    window.open(originalTab, "_self"); // navigate back to the original tab
    //  }
  };
  const initialURL = sessionStorage.getItem("initialURL");
  const cme_doctors = sessionStorage.getItem("cme_doctors");
  return (
    <>
      {initialURL == null && cme_doctors == null && (
        <>
          <header className="app-header">
            <div className="div-topbar">
              <div className="top-nav">
                {NavMenuList?.map((item, index) => {
                  return (
                    <div key={index} className="topnav-3">
                      <div className="frame-26">
                        <NavLink
                          to={`${item.path}`}
                          className="text-wrapper-dashborad"
                          data-tooltip-id={item.toolTipId}
                        >
                          {item.icon}
                          {item.menuName}
                        </NavLink>
                        {!userDetails?.upId &&
                          item?.menuName == "Profile" &&
                          isProfileRegister && (
                            <>
                              <div className="profileWalk" ref={divRef}>
                                <div className="popup-description">
                                  <img src={profileRegister} />
                                  <h3>
                                    {strings.profilePopup.fillRegistrationForm}
                                  </h3>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="div-tools">
                {/* <div
            className="alarm-wrapper"
            onClick={NotificationModelPopup}
          >
            <img
              className="alarm"
              alt="Alarm"
              src="/img/alarm.png"
            />
          </div> */}
                <InfoModel
                  open={NotificationInfoModel}
                  setOpen={setNotificationInfoModel}
                  buttonText="Ok"
                  heading="Notifications"
                >
                  <NotificationModel />
                </InfoModel>
                {userType == "PATIENT" && (
                  <>
                    {(familyMembers != null || profileAge >= 3) && (
                      <div className="shopping-cart-container">
                        <NavLink to={"/lab-services?page=testcart"}>
                          <MdOutlineShoppingCart className="shopping-cart-icon" />
                          {testLabOrderList && testLabOrderList.length > 0 ? (
                            <span className="shopping-cart-count">
                              {testLabOrderList.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </NavLink>
                      </div>
                    )}

                    {/* <button onClick={handleIconClick}>click</button> */}

                    {familyProfile == null && (
                      <div>
                        <FormControl
                          fullWidth
                          sx={{ minWidth: "200px" }}
                          size="small"
                        >
                          <InputLabel id="relationship-label">
                            Relationship
                          </InputLabel>
                          <Select
                            labelId="relationship-label"
                            id="relationship"
                            value={relationship}
                            label="Relationship"
                            onChange={handleChange}
                          >
                            <MenuItem value={""} disabled>
                              Select
                            </MenuItem>
                            {familyMembers?.map((member) => (
                              <MenuItem
                                key={member.id}
                                value={member.relationShipWithUser}
                                onClick={() => familyMemberLogin(member)}
                              >
                                {member.firstName} (
                                {member.relationShipWithUser})
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </>
                )}

                <div className="div-profile" ref={divRef2}>
                  <button className="button" onClick={PopuphandleClick}>
                    <ProfilePic
                      profileInformation={profileInformation}
                      initialProfile={initialProfile}
                    />
                  </button>
                </div>
              </div>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ProfilePopover />
            </Popover>
          </header>
        </>
      )}
    </>
  );
}

export default Header;
