import { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ScienceIcon from "@mui/icons-material/Science";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SpaIcon from "@mui/icons-material/Spa";
import BiotechIcon from "@mui/icons-material/Biotech";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LabServices from "../LabServices/LabServices";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import PathologyLabs from "./PathologyLabs";
import GymDetail from "../GymScreen/GymDetail";
import ZumbaTrainer from "../../components/FitnessTraining/zumbaTrainer";
import PhysiotherapistCareServices from "../PhysiotherapistScreens/PhysiotherapistCareServices";
import PsychiatristCareServices from "../Psychiatrist/PsychiatristCareServices";
import DoctorCareServices from "../DoctorsScreen/DoctorCareServices";
import DietitianCareServices from "../Dieticians/DietitianCareServices";

const categories = [
  {
    title: "Physical Fitness",
    icon: <FitnessCenterIcon />,
    subcategories: [
      {
        title: "Fitness Training",
        icon: <SportsGymnasticsIcon />,
        subcategories: [
          {
            title: "ZUMBA",
            icon: <DirectionsRunIcon />,
            subcategories: [
              {
                title: "Offline Zumba",
                icon: <StorefrontIcon />,
                // component: <ZumbaTrainer />,
              },
              { title: "Online Zumba", icon: <DesktopMacIcon /> },

            ],
          },
          {
            title: "CROSSFIT",
            icon: <FitnessCenterOutlinedIcon />,
            subcategories: [
              { title: "Offline CrossFit", icon: <StorefrontIcon /> },
              { title: "Online CrossFit", icon: <DesktopMacIcon /> },
            ],
          },
          {
            title: "YOGA",
            icon: <SelfImprovementOutlinedIcon />,
            subcategories: [
              { title: "Offline Yoga", icon: <StorefrontIcon /> },
              { title: "Online Yoga", icon: <DesktopMacIcon /> },
            ],
          },
          {
            title: "GYM",
            icon: <SportsKabaddiIcon />,
            component: <GymDetail />,
          },
        ],
      },
      {
        title: "Dietitian",
        icon: <RestaurantIcon />,
        subcategories: [
          { title: "Offline Dietitian", icon: <StorefrontIcon /> },
          {
            title: "Online Dietitian",
            icon: <DesktopMacIcon />,
            component: <DietitianCareServices />,
          },
        ],
      },
      {
        title: "Physiotherapy",
        icon: <HealthAndSafetyIcon />,
        subcategories: [
          {
            title: "Offline Physiotherapy",
            icon: <StorefrontIcon />,
          },
          {
            title: "Online Physiotherapy",
            icon: <DesktopMacIcon />,
            component: <PhysiotherapistCareServices />,
          },
        ],
      },
    ],
  },
  {
    title: "Mental Health",
    icon: <SelfImprovementIcon />,
    subcategories: [
      {
        title: "Physiological Counselling",
        icon: <PsychologyIcon />,
        subcategories: [
          {
            title: "Offline Physiological Counselling",
            icon: <StorefrontIcon />,
          },
          {
            title: "Online Physiological Counselling",
            icon: <DesktopMacIcon />,
          },
        ],
      },
      {
        title: "Psychiatrist",
        icon: <HealthAndSafetyIcon />,
        subcategories: [
          { title: "Offline Psychiatrist", icon: <StorefrontIcon /> },
          {
            title: "Online Psychiatrist",
            icon: <DesktopMacIcon />,
            component: <PsychiatristCareServices />,
          },
        ],
      },
      {
        title: "Nutritional Psychiatry",
        icon: <RestaurantIcon />,
        subcategories: [
          { title: "Offline Nutritional Psychiatry", icon: <StorefrontIcon /> },
          {
            title: "Online Nutritional Psychiatry",
            icon: <DesktopMacIcon />,
            component: (
              <DietitianCareServices partner="Nutritional Psychiatry" />
            ),
          },
        ],
      },
      {
        title: "Meditation",
        icon: <SpaIcon />,
        subcategories: [
          { title: "Offline Meditation", icon: <StorefrontIcon /> },
          { title: "Online Meditation", icon: <DesktopMacIcon /> },
        ],
      },
    ],
  },
  {
    title: "Medical Testing",
    icon: <ScienceIcon />,
    subcategories: [
      {
        title: "Pathology Lab",
        icon: <BiotechIcon />,
      },
      { title: "Genetic Testing", icon: <ScienceIcon /> },
      {
        title: "Doctor & Specialists",
        icon: <Diversity3Icon />,
        subcategories: [
          { title: "Offline Doctor & Specialists", icon: <StorefrontIcon /> },
          {
            title: "Online Doctor & Specialists",
            icon: <DesktopMacIcon />,
            component: <DoctorCareServices />,
          },
        ],
      },
    ],
  },
  {
    title: "Lifestyle Coaching",
    icon: <SelfImprovementIcon />,
    subcategories: [
      { title: "Offline Lifestyle Coaching", icon: <StorefrontIcon /> },
      { title: "Online Lifestyle Coaching", icon: <DesktopMacIcon /> },
    ],
  },
];

const WellnessServices = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedLab, setSelectedLab] = useState(null);
  const [defaultTab, setDefaultTab] = useState(0);

  console.log(
    "selectedItem",
    selectedItem,
    selectedCategory,
    selectedSubcategory
  );
  return (
    <div style={{ padding: "20px" }}>
      <Breadcrumbs separator=">">
        <Typography
          color="textPrimary"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => {
            setSelectedCategory(null);
            setSelectedSubcategory(null);
            setSelectedItem(null);
            setSelectedLab(null);
          }}
        >
          Wellness Services
        </Typography>
        {selectedCategory && (
          <Typography
            color={
              selectedSubcategory || selectedItem || selectedLab
                ? "textPrimary"
                : "primary"
            }
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => {
              setSelectedSubcategory(null);
              setSelectedItem(null);
              setSelectedLab(null);
            }}
          >
            {selectedCategory.title}
          </Typography>
        )}
        {selectedSubcategory && (
          <Typography
            color={selectedItem || selectedLab ? "textPrimary" : "primary"}
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => {
              setSelectedItem(null);
              setSelectedLab(null);
            }}
          >
            {selectedSubcategory.title}
          </Typography>
        )}
        {selectedItem && (
          <Typography color="primary" style={{ fontWeight: "bold" }}>
            {selectedItem.title}
          </Typography>
        )}
        {selectedLab && (
          <Typography color="primary" style={{ fontWeight: "bold" }}>
            {selectedLab}
          </Typography>
        )}
      </Breadcrumbs>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        {selectedItem?.component ? (
          selectedItem.component
        ) : selectedItem ? (
          selectedItem.subcategories?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (item.title === "Offline Zumba") {
                    setSelectedItem({
                      ...item,
                      component: (
                        <ZumbaTrainer mode="Offline" nameOfClass="ZUMBA" />
                      ),
                    });
                  } else if (item.title === "Online Zumba") {
                    setSelectedItem({
                      ...item,
                      component: (
                        <ZumbaTrainer mode="Online" nameOfClass="ZUMBA" />
                      ),
                    });
                  } else if (item.title === "Offline CrossFit") {
                    setSelectedItem({
                      ...item,
                      component: (
                        <ZumbaTrainer mode="Offline" nameOfClass="CROSSFIT" />
                      ),
                    });
                  } else if (item.title === "Online CrossFit") {
                    setSelectedItem({
                      ...item,
                      component: (
                        <ZumbaTrainer mode="Online" nameOfClass="CROSSFIT" />
                      ),
                    });
                  }
                }}
              >
                <IconButton>{item.icon}</IconButton>
                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                  {item.title}
                </Typography>
              </Card>
            </Grid>
          ))
        ) : selectedSubcategory ? (
          selectedSubcategory?.title === "Pathology Lab" ? (
            selectedLab ? (
              <LabServices selectedLab={selectedLab} defaultTab={defaultTab} />
            ) : (
              <PathologyLabs
                setSelectedLab={setSelectedLab}
                setDefaultTab={setDefaultTab}
              />
            )
          ) : selectedSubcategory?.title === "GYM" ? (
            <GymDetail />
          ) : (
            selectedSubcategory.subcategories?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedItem(item);
                    console.log("Selected Item:", item);
                  }}
                >
                  <IconButton>{item.icon}</IconButton>
                  <Typography variant="h6" style={{ marginLeft: "10px" }}>
                    {item.title}
                  </Typography>
                </Card>
              </Grid>
            ))
          )
        ) : selectedCategory ? (
          selectedCategory.subcategories.map((sub, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedSubcategory(sub)}
              >
                <IconButton>{sub.icon}</IconButton>
                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                  {sub.title}
                </Typography>
              </Card>
            </Grid>
          ))
        ) : (
          categories.map((category, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedCategory(category)}
              >
                <IconButton>{category.icon}</IconButton>
                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                  {category.title}
                </Typography>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default WellnessServices;
