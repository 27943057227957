import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  MenuItem,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import NoOrderImage from "./Images/no-order.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import "./style.css";
import { Phone } from "@mui/icons-material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import IncomingCall from "../../../static/img/IncomingCall.mp3";

const DieticianOrders = ({ partner }) => {
  const [rowData, setRowData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [proposalModalopen, setproposalModalopen] = useState(false);
  const [showDeclineForm, setShowDeclineForm] = useState(false);
  const [nextConsultDate, setNextConsultDate] = useState("");
  const [nextConsultTime, setNextConsultTime] = useState("");
  const [error, setError] = useState("");
  const [slots, setSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState({});
  const [currentOrder, setCurrentOrder] = useState(null);
  const [isCallIncoming, setIsCallIncoming] = useState(false);
  const [audioInstance, setAudioInstance] = useState(null);
  const location = useLocation();

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleAccept = () => {
    const formData = {
      orderId: selectedOrder.orderId,
      status: "ACCEPT",
    };
    AxiosInstance.post("consultation/consultation-status-update", formData)
      .then((response) => {
        setOpen(false);
        toast.success("Consultation Accepted. Now you can review your client.");
        if (partner === "Physiotherapist") {
          navigate("/physiotherapist-online-consultation", {
            state: { order: selectedOrder },
          });
        } else if (partner === "Psychiatrist") {
          navigate("/psychiatrist-online-consultation", {
            state: { order: selectedOrder },
          });
        } else {
          navigate("/dietician-online-consultation", {
            state: { order: selectedOrder },
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const handleProtocolAndProposalView = () => {
    if (partner === "Physiotherapist") {
      navigate("/physiotherapist-online-consultation", {
        state: { order: selectedOrder },
      });
    } else if (partner === "Psychiatrist") {
      navigate("/psychiatrist-online-consultation", {
        state: { order: selectedOrder },
      });
    } else {
      navigate("/dietician-online-consultation", {
        state: { order: selectedOrder },
      });
    }
  };

  const handleReview = (order) => {
    if (partner === "Physiotherapist") {
      navigate("/physiotherapist-online-consultation", {
        state: {
          order: order,
          tabIndex: order.statusForPartner === "Review Details" ? undefined : 6,
        },
      });
    } else if (partner === "Psychiatrist") {
      navigate("/psychiatrist-online-consultation", {
        state: {
          order: order,
          tabIndex: order.statusForPartner === "Review Details" ? undefined : 6,
        },
      });
    } else {
      navigate("/dietician-online-consultation", {
        state: {
          order: order,
          tabIndex: order.statusForPartner === "Review Details" ? undefined : 6,
        },
      });
    }
  };

  const getDietitianOrders = () => {
    setIsLoading(true);
    const userDetails = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userDetails);
    const userType = parsedData.userType;
    console.log("userType", userType);
    AxiosInstance.get(`consultation/orderDetails/${userType}`)
      .then((response) => {
        if (response != null) {
          setIsLoading(false);
          setOrders(response);
        } else {
          setIsLoading(false);
          setOrders([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
  };

  useEffect(() => {
    // getLabOrderDetail();
    // const today = new Date().toISOString().substr(0, 10);
    // setSelectedDate(today);
    getDietitianOrders();
  }, []);

  const handleOpen = (order) => {
    if (order.statusForPartner == "PCB") {
      setproposalModalopen(true);
      setSelectedOrder(order);
    } else {
      setOpen(true);
      setSelectedOrder(order);
    }
  };
  const handleProposalModalClose = () => {
    setproposalModalopen(false);
    setSelectedOrder(null);
  };
  const handleClose = () => {
    setOpen(false);
    setShowDeclineForm(false);
    setNextConsultDate("");
    setNextConsultTime("");
    setError("");
  };

  const getUserIdFromToken = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      try {
        // Decode the payload of the token (which is the second part of the JWT)
        const base64Url = token.split(".")[1]; // Get the payload part of the JWT
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Replace URL-safe characters
        const decodedPayload = JSON.parse(atob(base64)); // Decode and parse the JSON

        return decodedPayload.ud; // Adjust key based on your token structure
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    return null;
  };

  const fetchTimeSlots = () => {
    const userId = parseInt(getUserIdFromToken()); // Get the user ID dynamically
    if (!userId) {
      console.error("User ID not found in token.");
      setLoading(false);
      return;
    }
    AxiosInstance.get(`consultation/get-consultant-session/${userId}`)
      .then((response) => {
        if (response != null) {
          const today = new Date().toISOString().split("T")[0];
          const upcomingDates = [
            ...new Set(
              response
                .filter(
                  (slot) =>
                    slot.scheduleDate >= today && slot.status === "Available"
                )
                .map((slot) => slot.scheduleDate)
            ),
          ];
          setAvailableDates(upcomingDates);
          setSlots(response);
        } else {
          setSlots([]);
          setAvailableDates([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching sessions:", err);
        setLoading(false);
      });
  };

  console.log("avaialble dates", availableDates, availableTimes);

  const handleDecline = () => {
    setLoading(true);
    setShowDeclineForm(true);
    setError(""); // Clear error on decline
    fetchTimeSlots();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(""); // Reset selected time when date changes

    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentTimeInMinutes = currentHours * 60 + currentMinutes;

    // Filter available times for the selected date and exclude past slots for today
    const times = slots.filter((slot) => {
      if (slot.scheduleDate === date && slot.status === "Available") {
        if (date === new Date().toISOString().split("T")[0]) {
          // Today's date: filter out past slots
          const [startHours, startMinutes] = slot.startTime
            .split(":")
            .map(Number);
          const slotTimeInMinutes = startHours * 60 + startMinutes;
          return slotTimeInMinutes >= currentTimeInMinutes; // Only include future slots
        }
        // For other dates, include all available slots
        return true;
      }
      return false;
    });
    // .map((slot) => `${slot.startTime} - ${slot.endTime}`);

    setAvailableTimes(times);
    setError("");
  };

  const handleTimeChange = (e) => {
    setNextConsultTime(e.target.value);
    if (nextConsultDate && e.target.value) {
      setError(""); // Clear error if both fields are filled
    }
  };

  const handleSubmit = () => {
    if (!selectedDate || !selectedTime) {
      setError("Please fill in both the date and time.");
    } else {
      setError("");
      console.log(
        "Next Consultation Scheduled:",
        selectedDate,
        selectedTime,
        selectedSlot.id
      );
      const formData = {
        scheduleId: selectedSlot.id,
        orderId: selectedOrder.orderId,
        status: "DECLINE",
      };
      AxiosInstance.post("consultation/consultation-status-update", formData)
        .then((response) => {
          setOpen(false);
          toast.success("Appointment Date/Time Changed");
          getDietitianOrders();
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      setSelectedTime("");
      setSelectedDate("");
      setSelectedSlot(null);
      // Submit the next consultation data here
      handleClose();
    }
  };

  const isWithinAppointmentTime = (
    appointmentDate,
    appointmentTimeStartTime,
    appointmentTimeEndTime
  ) => {
    const currentDate = new Date();
    const startDateTime = new Date(
      `${appointmentDate}T${appointmentTimeStartTime}`
    );
    const endDateTime = new Date(
      `${appointmentDate}T${appointmentTimeEndTime}`
    );
    return currentDate >= startDateTime && currentDate <= endDateTime;
  };

  const joinMeeting = async (order) => {
    console.log("meeting ", order);

    const orderString = encodeURIComponent(JSON.stringify(order));

    const userType = "Dietitian";

    // Create the meeting URL with both order and userType
    const meetingUrl = `/meeting?order=${orderString}&userType=${encodeURIComponent(
      userType
    )}`;
    handleCloseCall();
    // Open the meeting URL in a new tab
    window.open(meetingUrl, "_blank");
  };

  const filteredOrders = rowData.filter((order) => order.date === selectedDate);

  console.log("selected ", selectedOrder, statuses);
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to fetch call status repeatedly until a non-null response is received
  const fetchCallStatusUntilNonNull = async (order, isMounted) => {
    const appointmentTimeEnd = new Date(
      `${order.appointmentDate}T${order.appointmentTimeEndTime}:00`
    ).getTime();
    let result = null;

    while (isMounted.current && result === null) {
      const now = new Date().getTime();

      // Stop polling if the current time exceeds the appointment end time
      if (
        order.statusForPartner !== "REVIEWED" &&
        order.statusForPartner !== "PCB" &&
        order.statusForPartner !== "QRR"
      ) {
        return;
      }
      if (now > appointmentTimeEnd) {
        console.log(`End time reached for orderId: ${order.orderId}`);
        return;
      }
      console.log("location.pathname", location.pathname);

      if (
        location.pathname !== "/dietician-Order" &&
        location.pathname !== "/physiotherapist-Order"
      ) {
        console.log(
          `Not on the correct route. Stopping API polling for orderId: ${order.orderId}`
        );
        return; // Stop if the user is not on the correct route
      }

      try {
        console.log(`Fetching call status for orderId: ${order.orderId}`);
        const response = await AxiosInstance.get(
          `consultation/get-call-status/${order.orderId}`
        );
        console.log("call status", response);

        // Assuming the relevant data is in `response.data`
        if (response) {
          result = response.patientCallStatus;
          const dietitianCallStatus = response.dietitianCallStatus;
          console.log("Non-null response received:", result);

          // Update status only if dietitianCallStatus is null or empty
          if (dietitianCallStatus == null) {
            setStatuses((prevStatuses) => ({
              ...prevStatuses,
              [order.orderId]: result, // Update status for the specific order
            }));
            setCurrentOrder({ ...order, patientCallStatus: result });
            handleIncomingCall();
          }
          // setIsCallIncoming(true);
        } else {
          console.log("Response was null. Retrying...");
          await wait(2000); // Wait for 2 seconds before retrying
        }
      } catch (err) {
        console.error("Error fetching call status:", err);
        await wait(2000); // Wait before retrying on error
      }
    }

    return result;
  };

  const handleIncomingCall = () => {
    const audio = new Audio(IncomingCall);
    audio.loop = true; // Keep looping while the modal is open
    audio.play().catch((err) => console.error("Audio playback failed:", err));

    // Store the Audio instance in state
    setAudioInstance(audio);
    setIsCallIncoming(true);
  };

  const handleCloseCall = () => {
    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0; // Reset playback to the start
    }
    setIsCallIncoming(false);
  };

  useEffect(() => {
    console.log("location.pathname inside useEffect", location.pathname);
    const isMounted = { current: true };
    const now = new Date();

    orders.forEach((order) => {
      const appointmentDate = new Date(order.appointmentDate);
      const appointmentTimeStart = new Date(
        `${order.appointmentDate}T${order.appointmentTimeStartTime}:00`
      );
      const appointmentTimeEnd = new Date(
        `${order.appointmentDate}T${order.appointmentTimeEndTime}:00`
      ).getTime();

      // Check if the order's appointment date is today
      if (appointmentDate.toDateString() === now.toDateString()) {
        const timeDifference = appointmentTimeStart - now;

        // If the appointment starts in less than 2 minutes
        if (
          timeDifference <= 2 * 60 * 1000 &&
          now.getTime() < appointmentTimeEnd
        ) {
          console.log(`Monitoring call status for orderId: ${order.orderId}`);
          fetchCallStatusUntilNonNull(order, isMounted);
        }
      }
    });
    return () => {
      console.log("Cleanup: Stopping all API polling.");
      isMounted.current = false;
    };
  }, [orders, location.pathname]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        {/* <div
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          width: "25%",
        }}
      >
        <b>Date :</b>
        <span style={{ marginLeft: "10px" }}>
          <input
            type="date"
            className="form-control"
            id="dateInput"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </span>
      </div> */}
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you your orders from Uncurl: Health</b>
      </p>
      {isLoading ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", mr: 2 }}
            >
              QRR = Questionnaire Response Recieved
            </Typography>
            {/* <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5" }}
            >
              RR = Response Received
            </Typography> */}
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", mr: 2 }}
            >
              P&P = Protocol and Proposal
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", mr: 2 }}
            >
              PCB = Proposal Call Booked
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5" }}
            >
              PQR = Protocol Questions Recieved
            </Typography>
          </Box>
          <TableContainer style={{ border: "1px solid black" }}>
            <Table>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Time</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>ID</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Service Type</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Status</b>
                  </p>
                </TableCell>
                {/* <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Call</b>
                  </p>
                </TableCell> */}
              </TableRow>
              <TableBody>
                {orders?.map((orders, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.createdOn ? formatDate(orders.createdOn) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.time ? formatTime(orders.time) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.orderType}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.statusForPartner === "Consultation Request" ||
                      orders.statusForPartner === "PCB" ||
                      orders.statusForPartner === "Questions" ? (
                        <Button
                          variant="contained"
                          style={{
                            boxShadow: "0px 0px 10px 0px red",
                          }}
                          className="doctor-review-button"
                          onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 20px 5px red";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 10px 0px red";
                          }}
                          onClick={() => handleOpen(orders)}
                        >
                          {orders.statusForPartner}
                        </Button>
                      ) : (
                        <>
                          {orders.statusForPartner === "Order Closed" ? (
                            <div>
                              {orders.statusForPartner} (
                              {formatDate(orders.closedDate)}/
                              {formatTime(orders.closedTime)})
                            </div>
                          ) : orders.statusForPartner === "Review Details" ||
                            orders.statusForPartner === "REVIEWED" ||
                            orders.statusForPartner === "Call Completed" ||
                            orders.statusForPartner === "QRR" ||
                            orders.statusForPartner === "PQR" ||
                            orders.statusForPartner === "PRS" ? (
                            <>
                              <Button
                                variant="contained"
                                style={{
                                  boxShadow: "0px 0px 10px 0px green",
                                }}
                                className="dietitian-review-button"
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.boxShadow =
                                    "0px 0px 20px 5px green";
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.boxShadow =
                                    "0px 0px 10px 0px green";
                                }}
                                onClick={() => handleReview(orders)}
                              >
                                {orders.statusForPartner}
                              </Button>
                              {/* {orders.statusForPartner === "REVIEWED" && (
                                // isWithinAppointmentTime(
                                //   orders.appointmentDate,
                                //   orders.appointmentTimeStartTime,
                                //   orders.appointmentTimeEndTime
                                // ) &&
                                <>
                                  <Phone
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                      color: "blue",
                                    }}
                                    onClick={() => joinMeeting(orders)}
                                  />
                                </>
                              )} */}
                            </>
                          ) : (
                            orders.statusForPartner
                          )}
                        </>
                      )}
                    </TableCell>
                    {/* <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {(orders.statusForPartner === "REVIEWED" ||
                        orders.statusForPartner === "PCB") && ( */}
                    {/* // isWithinAppointmentTime(
                        //   orders.appointmentDate,
                        //   orders.appointmentTimeStartTime,
                        //   orders.appointmentTimeEndTime
                        // ) && */}
                    {/* <>
                          <PhoneInTalkIcon
                            sx={{
                              fontSize: "30px",
                              color: "#4caf50", // Green for accepting calls
                              animation: "pulse 1.5s infinite",
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => joinMeeting(orders)}
                          />
                        </>
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                Consultation Details
              </Typography>

              <Box sx={{ mt: 4, mb: 2 }}>
                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Appointment Date:</strong>{" "}
                      {selectedOrder &&
                        formatDate(selectedOrder.appointmentDate)}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Appointment Time:</strong>{" "}
                      {selectedOrder &&
                        `${formatTime(
                          selectedOrder.appointmentTimeStartTime
                        )} - ${formatTime(
                          selectedOrder.appointmentTimeEndTime
                        )}`}
                    </Typography>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Name:</strong>{" "}
                      {selectedOrder && selectedOrder.consumerName}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Age:</strong>{" "}
                      {selectedOrder && selectedOrder.consumerAge}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Gender:</strong>{" "}
                      {selectedOrder && selectedOrder.consumerGender}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Type of Call:</strong>{" "}
                      {selectedOrder && selectedOrder.typeOfCall}
                    </Typography>
                  </Col>
                </Row>
              </Box>

              <Box
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAccept}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDecline}
                >
                  Decline
                </Button>
              </Box>

              {/* Next Consultation Form */}
              {/* {nextConsultDate === "" && (
                <Box mt={3}>
                  <TextField
                    label="Next Consultation Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={nextConsultDate}
                    onChange={(e) => setNextConsultDate(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="Next Consultation Time"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={nextConsultTime}
                    onChange={(e) => setNextConsultTime(e.target.value)}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Box>
              )} */}
              {showDeclineForm && (
                <Box mt={3}>
                  <Typography variant="subtitle1" mb={1}>
                    Schedule Next Consultation
                  </Typography>
                  {loading ? (
                    <Spinner></Spinner>
                  ) : availableDates.length === 0 ? (
                    <Typography variant="h6" color="error" align="center">
                      No available dates. Please go to manage schedule and
                      select available date and time.
                    </Typography>
                  ) : (
                    <>
                      <Row>
                        <Col lg={6}>
                          <TextField
                            select
                            label="Next Consultation Date"
                            fullWidth
                            value={selectedDate}
                            onChange={(e) => handleDateChange(e.target.value)}
                            margin="normal"
                          >
                            {availableDates.map((date) => (
                              <MenuItem key={date} value={date}>
                                {date}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Col>
                        <Col lg={6}>
                          <TextField
                            select
                            label="Next Consultation Time"
                            fullWidth
                            value={selectedSlot ? selectedSlot.id : ""}
                            onChange={(e) => {
                              const selectedSlotId = e.target.value;
                              const slot = availableTimes.find(
                                (s) => s.id === selectedSlotId
                              );
                              setSelectedSlot(slot);
                              setSelectedTime(e.target.value);
                              setError("");
                            }}
                            margin="normal"
                            disabled={!selectedDate}
                          >
                            {availableTimes.length > 0 ? (
                              availableTimes.map((slot) => (
                                <MenuItem key={slot.id} value={slot.id}>
                                  {`${slot.startTime} - ${slot.endTime}`}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                No time slots are available
                              </MenuItem>
                            )}
                          </TextField>
                        </Col>
                      </Row>

                      {error && (
                        <Typography color="error" mt={1}>
                          {error}
                        </Typography>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        style={{ marginTop: "10px" }}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Modal>
          <Modal
            open={proposalModalopen}
            onClose={handleProposalModalClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <IconButton
                onClick={handleProposalModalClose}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                Call to discuss Proposal
              </Typography>

              <Box sx={{ mt: 4, mb: 2 }}>
                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Appointment Date:</strong>{" "}
                      {selectedOrder &&
                        formatDate(selectedOrder.appointmentDate)}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Appointment Time:</strong>{" "}
                      {selectedOrder &&
                        `${formatTime(selectedOrder.appointmentTimeStartTime)}`}
                    </Typography>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Name:</strong>{" "}
                      {selectedOrder && selectedOrder.consumerName}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Age:</strong>{" "}
                      {selectedOrder && selectedOrder.consumerAge}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Gender:</strong>{" "}
                      {selectedOrder && selectedOrder.consumerGender}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Type of Call:</strong>{" "}
                      {selectedOrder && selectedOrder.typeOfCall}
                    </Typography>
                  </Col>
                </Row>
              </Box>

              <Box
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleProtocolAndProposalView}
                >
                  View Protocol and Proposal
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                No Orders Available!
              </h4>
              <img src={NoOrderImage} alt="Lab" style={{ width: "25%" }} />
            </span>
          </div>
        </>
      )}

      {currentOrder && (
        <Modal open={isCallIncoming}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "15px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: "50%",
            }}
          >
            <PhoneInTalkIcon
              sx={{
                fontSize: "60px",
                color: "#4caf50",
                animation: "pulse 1.5s infinite",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#333",
                marginTop: "15px",
                marginBottom: "20px",
              }}
            >
              Incoming Call from Patient:{" "}
              {currentOrder.consumerName || "Unknown"}
            </Typography>
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "baseline",
                width: "50%",
              }}
            >
              <Button
                variant="contained"
                onClick={() => joinMeeting(currentOrder)}
                sx={{
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  padding: "10px 20px",
                  "&:hover": {
                    backgroundColor: "#43a047",
                  },
                }}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleCloseCall()}
                sx={{
                  borderColor: "#f44336",
                  color: "#f44336",
                  padding: "10px 20px",
                  "&:hover": {
                    backgroundColor: "#f4433611",
                  },
                }}
              >
                Decline
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default DieticianOrders;
