import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import NoOrderImage from "./Images/no-order.jpeg";
import Spinner from "../../shared/Spinner";

const DietitianOrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const userDetails = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userDetails);
    const userType = parsedData.userType;
    console.log("userType", userType);
    AxiosInstance.get(`consultation/get-order-history/${userType}`)
      .then((response) => {
        if (response != null) {
          setIsLoading(false);
          setOrders(response);
        } else {
          setIsLoading(false);
          setOrders([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
  }, []);

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleRowClick = (order, index) => {
    setSelectedOrder(order);
    setClickedRowIndex(index);
  };

  console.log("orders", orders, selectedOrder);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>
          This screen will show you your Completed orders from Uncurl: Health
        </b>
      </p>

      {isLoading ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <>
          <TableContainer
            style={{ marginTop: "2%", border: "1px solid black" }}
          >
            <Table>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Time</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>ID</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Service Type</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Status</b>
                  </p>
                </TableCell>
              </TableRow>
              <TableBody>
                {orders?.map((orders, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(orders, index)}
                  >
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.createdOn ? formatDate(orders.createdOn) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.time ? formatTime(orders.time) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.orderType}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      <>
                        {orders.statusForPartner === "Order Closed" ? (
                          <div>
                            {orders.statusForPartner} (
                            {formatDate(orders.closedDate)}/
                            {formatTime(orders.closedTime)})
                          </div>
                        ) : (
                          orders.statusForPartner
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                No Orders Available!
              </h4>
              <img src={NoOrderImage} alt="Lab" style={{ width: "25%" }} />
            </span>
          </div>
        </>
      )}
    </>
  );
};
export default DietitianOrderHistory;
