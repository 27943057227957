import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { Button, Checkbox, FormControlLabel, FormControl, Grid, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Typography, Box } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ModalForFileView from "../../shared/ModalForFileView";

const predefinedDiseases = ["CVD", "BipolarDisorder", "CyclothymicDisorder", "GeneralizedAnxietyDisorder", "Colorectal Cancer", "Prostate Cancer", "Cervical Cancer", "Oral Cancer"];

const PsychiatristGeneralInfo = (selectedUser, approval) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [registeredBusiness, setRegisteredBusiness] = useState(null);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState("");
  const [organizationBusinessTime, setOrganizationBusinessTime] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [psychiatristDetail, setPsychiatristDetail] = useState("");
  const [educationQualifications, setEducationQualifications] = useState("");
  const [yearsWorkingAsPsychiatrist, setYearsWorkingAsPsychiatrist] = useState();
  const [everWorkedAsClinicalPsychiatrist, setEverWorkedAsClinicalPsychiatrist] = useState(null);
  const [hospitalName, setHospitalName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [yearsWorkingAtHospital, setYearsWorkingAtHospital] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [psychiatristConsultingForDiseasesForClients, setPsychiatristConsultingForDiseasesForClients] = useState("");
  const [historyOfWorkingWithChild, setHistoryOfWorkingWithChild] = useState("");
  const [detailsOfMethodologyOrDietaryGuidelinesFollowed, setDetailsOfMethodologyOrDietaryGuidelinesFollowed] = useState("");
  const [genderPreference, setGenderPreference] = useState("");
  const [childrenFromBirthTillThreeYearsAge, setChildrenFromBirthTillThreeYearsAge] = useState("");
  const [childrenFromTwoToTwelveYearsAge, setChildrenFromTwoToTwelveYearsAge] = useState("");
  const [childrenFromThirteenToTwentyYearsAge, setChildrenFromThirteenToTwentyYearsAge] = useState("");
  const [adultMaleFromTwentyOneToSixtyYearsAge, setAdultMaleFromTwentyOneToSixtyYearsAge] = useState("");
  const [adultFemaleFromTwentyOneToSixtyYearsAge, setAdultFemaleFromTwentyOneToSixtyYearsAgee] = useState("");
  const [seniorsOfAgeGreaterThenSixty, setSeniorsOfAgeGreaterThenSixty] = useState("");
  const [sharingReferences, setSharingReferences] = useState(null);
  const [diseasesOtherValue, setDiseasesOtherValue] = useState("");
  const [outstandingTestimonialsFileList, setOutstandingTestimonialsFileList] = useState([]);
  const [documentsFileList, setDocumentsFileList] = useState([]);
  const [formData, setFormData] = useState({
    Depression: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    BipolarDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    CyclothymicDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    GeneralizedAnxietyDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    PanicDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    SocialAnxietyDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    SpecificPhobias: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    ObsessiveCompulsiveDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    PostTraumaticStressDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    Schizophrenia: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    SchizoaffectiveDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    BriefPsychoticDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    DelusionalDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    BorderlinePersonalityDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    AntisocialPersonalityDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    NarcissisticPersonalityDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    EatingDisorders: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    SubstanceUseDisorders: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    AttentionDeficitOrHyperactivityDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
    AutismSpectrumDisorder: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
      workedWithSeniors: "",
    },
  });

  const [referenceDetail, setReferenceDetail] = useState({
    reference1: {
      name: "",
      mobile: "",
    },
    reference2: {
      name: "",
      mobile: "",
    },
    reference3: {
      name: "",
      mobile: "",
    },
  });

  const [preferredDayAndTime, setPreferredDayAndTime] = useState({
    Weekdays: [],
    time: "",
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedTestimonialFiles, setSelectedTestimonialFiles] = useState([]);
  const [fileUploading, setfileUploading] = useState(false);
  const [testimonialfileUploading, settestimonialfileUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTestimonialModalOpen, setisTestimonialModalOpen] = useState(false);
  const [showRegisteredOrganization, setShowRegisteredOrganization] = useState();
  const [showEverWorkedasClicnicalAdvisor, setShowEverWorkedasClicnicalAdvisor] = useState();
  const [showReferences, setShowReferences] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleRegisteredBusiness = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setRegisteredBusiness(isBusinessRegistered);
    setShowRegisteredOrganization(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registeredBusiness: false,
    }));
  };

  const handleeverWorkedAsClinicalDietitian = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setEverWorkedAsClinicalPsychiatrist(isBusinessRegistered);
    setShowEverWorkedasClicnicalAdvisor(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      everWorkedAsClinicalPsychiatrist: false,
    }));
  };

  const handlesharingReferences = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setShowReferences(isBusinessRegistered);
    setSharingReferences(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      sharingReferences: false,
    }));
    if (!isBusinessRegistered) {
      setReferenceDetail({
        reference1: {
          name: "",
          mobile: "",
        },
        reference2: {
          name: "",
          mobile: "",
        },
        reference3: {
          name: "",
          mobile: "",
        },
      });
    }
  };

  const handleDietitianDetail = (event) => {
    setPsychiatristDetail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      psychiatristDetail: false,
    }));
  };

  const handleEducationQualification = (event) => {
    setEducationQualifications(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      educationQualifications: false,
    }));
  };

  const handleYearsWorkingAsDietitian = (event) => {
    setYearsWorkingAsPsychiatrist(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearsWorkingAsPsychiatrist: false,
    }));
  };

  const handleHistoryOfWorkingWithChild = (event) => {
    setHistoryOfWorkingWithChild(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      historyOfWorkingWithChild: false,
    }));
  };

  const handleDetailsOfMethodologyOrDietaryGuidelinesFollowed = (event) => {
    setDetailsOfMethodologyOrDietaryGuidelinesFollowed(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      detailsOfMethodologyOrDietaryGuidelinesFollowed: false,
    }));
  };

  const handleGenderPreference = (event) => {
    setGenderPreference(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      genderPreference: false,
    }));
  };

  const handleGenderChange = (disease, gender) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], gender: !gender }, // Remove error if gender is selected
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        gender,
      },
    }));
  };

  const handleAgeGroupChange = (disease, ageGroup) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], ageGroup: false }, // Remove error when checkbox is checked
    }));
    setFormData((prevFormData) => {
      const existingAgeGroups = prevFormData?.[disease]?.ageGroup ?? [];
      const newAgeGroups = existingAgeGroups.includes(ageGroup) ? existingAgeGroups.filter((group) => group !== ageGroup) : [...existingAgeGroups, ageGroup];

      return {
        ...prevFormData,
        [disease]: {
          ...(prevFormData?.[disease] ?? {}),
          ageGroup: newAgeGroups,
        },
      };
    });
  };

  const handleYearsChange = (disease, years) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], years: !years }, // Remove error if input is filled
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        years,
      },
    }));
  };

  const handlePatientsHelpedChange = (disease, patientsHelped) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], patientsHelped: !patientsHelped }, // Remove error if input is filled
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        patientsHelped,
      },
    }));
  };

  const handleWorkedWithSeniorsChange = (disease, workedWithSeniors) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], workedWithSeniors: !workedWithSeniors }, // Remove error if input is filled
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        workedWithSeniors,
      },
    }));
  };

  const handleWeekDays = (event) => {
    const value = event.target.value;

    setPreferredDayAndTime((prevState) => {
      const currentValues = prevState.Weekdays;

      if (currentValues.includes(value)) {
        // Remove the day if it's already in the array
        return {
          ...prevState,
          Weekdays: currentValues.filter((day) => day !== value),
        };
      } else {
        // Add the day if it's not in the array
        return {
          ...prevState,
          Weekdays: [...currentValues, value],
        };
      }
    });

    // Optionally reset field errors if needed
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      preferredDayAndTime: false,
    }));
  };

  const handleTime = (name, value) => {
    setPreferredDayAndTime((prevState) => ({
      ...prevState,
      time: value,
    }));
  };

  const handleReferenceUpdate = (reference, field, value) => {
    setReferenceDetail((prevReferences) => ({
      ...prevReferences,
      [reference]: {
        ...(prevReferences?.[reference] ?? {}),
        [field]: value,
      },
    }));
    if (reference === "reference1" && field === "name") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        reference1Name: false,
      }));
    }

    if (reference === "reference1" && field === "mobile") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        reference1Mobile: false,
      }));
    }
  };

  const handleOrganizationName = (event) => {
    setOrganizationName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationName: false,
    }));
  };
  const handleOrganizationPhoneNumber = (event) => {
    const phoneNumber = event.target.value;
    setOrganizationPhoneNumber(phoneNumber);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationPhoneNumber: false,
    }));
  };

  const handleOrganizationBusinessTime = (event) => {
    setOrganizationBusinessTime(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationBusinessTime: false,
    }));
  };
  const handleOrganizationAddress = (event) => {
    setOrganizationAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationAddress: false,
    }));
  };
  const handleHospitalName = (event) => {
    setHospitalName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      hospitalName: false,
    }));
  };
  const handleState = (event) => {
    setState(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      state: false,
    }));
  };
  const handleCity = (event) => {
    setCity(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      city: false,
    }));
  };
  const handleHowManyYears = (event) => {
    setYearsWorkingAtHospital(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearsWorkingAtHospital: false,
    }));
  };
  const handleSpecialization = (event) => {
    setSpecialization(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      specialization: false,
    }));
  };

  const yearsWorkingAsDietitianInt = parseInt(yearsWorkingAsPsychiatrist, 10);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = {};

    if (registeredBusiness == null) {
      error.registeredBusiness = true;
    }
    if (registeredBusiness == true) {
      if (organizationName == null || organizationName == "") {
        error.organizationName = true;
      }
      if (organizationPhoneNumber == null || organizationPhoneNumber == "") {
        error.organizationPhoneNumber = true;
      }
      if (organizationPhoneNumber != null) {
        if (organizationPhoneNumber.length !== 10) {
          error.organizationPhoneNumber = true;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter a valid 10-digit Phone Number",
          });
        }
      }
      if (organizationBusinessTime == null || organizationBusinessTime == "") {
        error.organizationBusinessTime = true;
      }
      if (organizationAddress == null || organizationAddress == "") {
        error.organizationAddress = true;
      }
    }
    if (psychiatristDetail == null || psychiatristDetail == "") {
      error.psychiatristDetail = true;
    }
    if (educationQualifications == null || educationQualifications == "") {
      error.educationQualifications = true;
    }
    if (yearsWorkingAsPsychiatrist == null || yearsWorkingAsPsychiatrist == "") {
      error.yearsWorkingAsPsychiatrist = true;
    }
    if (everWorkedAsClinicalPsychiatrist == null) {
      error.everWorkedAsClinicalPsychiatrist = true;
    }
    if (everWorkedAsClinicalPsychiatrist == true) {
      if (hospitalName == null || hospitalName == "") {
        error.hospitalName = true;
      }
      if (state == null || state == "") {
        error.state = true;
      }
      if (city == null || city == "") {
        error.city = true;
      }
      if (yearsWorkingAtHospital == null || yearsWorkingAtHospital == "") {
        error.yearsWorkingAtHospital = true;
      }
      if (specialization == null || specialization == "") {
        error.specialization = true;
      }
    }
    if (historyOfWorkingWithChild == null || historyOfWorkingWithChild == "") {
      error.historyOfWorkingWithChild = true;
    }
    // if (
    //   !psychiatristConsultingForDiseasesForClients ||
    //   psychiatristConsultingForDiseasesForClients.trim() === ""
    // ) {
    //   error.psychiatristConsultingForDiseasesForClients = true;
    // }
    if (detailsOfMethodologyOrDietaryGuidelinesFollowed == null || detailsOfMethodologyOrDietaryGuidelinesFollowed == "") {
      error.detailsOfMethodologyOrDietaryGuidelinesFollowed = true;
    }
    if (genderPreference == null || genderPreference == "") {
      error.genderPreference = true;
    }
    if (sharingReferences == null) {
      error.sharingReferences = true;
    }
    if (preferredDayAndTime == null || preferredDayAndTime == "") {
      error.preferredDayAndTime = true;
    }
    if (childrenFromBirthTillThreeYearsAge == null || childrenFromBirthTillThreeYearsAge == "" || childrenFromBirthTillThreeYearsAge == "null") {
      error.childrenFromBirthTillThreeYearsAge = true;
    }
    if (childrenFromTwoToTwelveYearsAge == null || childrenFromTwoToTwelveYearsAge == "" || childrenFromTwoToTwelveYearsAge == "null") {
      error.childrenFromTwoToTwelveYearsAge = true;
    }
    if (childrenFromThirteenToTwentyYearsAge == null || childrenFromThirteenToTwentyYearsAge == "" || childrenFromThirteenToTwentyYearsAge == "null") {
      error.childrenFromThirteenToTwentyYearsAge = true;
    }
    if (adultMaleFromTwentyOneToSixtyYearsAge == null || adultMaleFromTwentyOneToSixtyYearsAge == "" || adultMaleFromTwentyOneToSixtyYearsAge == "null") {
      error.adultMaleFromTwentyOneToSixtyYearsAge = true;
    }
    if (adultFemaleFromTwentyOneToSixtyYearsAge == null || adultFemaleFromTwentyOneToSixtyYearsAge == "" || adultFemaleFromTwentyOneToSixtyYearsAge == "null") {
      error.adultFemaleFromTwentyOneToSixtyYearsAge = true;
    }

    if (seniorsOfAgeGreaterThenSixty == null || seniorsOfAgeGreaterThenSixty == "" || seniorsOfAgeGreaterThenSixty == "null") {
      error.seniorsOfAgeGreaterThenSixty = true;
    }

    Object.keys(formData).forEach((disease) => {
      const currentDiseaseData = formData[disease];

      // Check if any field in the current disease is filled
      const isAnyFieldFilled =
        currentDiseaseData?.gender || currentDiseaseData?.ageGroup?.length || currentDiseaseData?.years || currentDiseaseData?.patientsHelped || currentDiseaseData?.workedWithSeniors;

      // Set errors only if any field is filled but not all required fields
      if (isAnyFieldFilled) {
        if (!currentDiseaseData?.gender) {
          error[disease] = { ...error[disease], gender: true };
        }

        if (!currentDiseaseData?.ageGroup?.length) {
          error[disease] = { ...error[disease], ageGroup: true };
        }

        if (!currentDiseaseData?.years) {
          error[disease] = { ...error[disease], years: true };
        }

        if (!currentDiseaseData?.patientsHelped) {
          error[disease] = { ...error[disease], patientsHelped: true };
        }
        if (!currentDiseaseData?.workedWithSeniors) {
          error[disease] = { ...error[disease], workedWithSeniors: true };
        }
      }
    });

    if (sharingReferences === true) {
      if (!referenceDetail.reference1.name || referenceDetail.reference1.name.trim() === "") {
        error.reference1Name = true;
      }

      if (!referenceDetail.reference1.mobile || referenceDetail.reference1.mobile.trim() === "") {
        error.reference1Mobile = true;
      }
      // if (
      //   referenceDetail.reference1.name &&
      //   referenceDetail.reference1.name.trim() !== "" &&
      //   referenceDetail.reference1.mobile &&
      //   referenceDetail.reference1.mobile.trim() !== ""
      // ) {
      //   error.reference1Name = false; // Set it to false if no errors
      //   error.reference1Mobile = false
      // }
    }

    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formDataCheck = new FormData();

      formDataCheck.append("firstName", firstName);
      formDataCheck.append("lastName", lastName);
      formDataCheck.append("email", email);
      formDataCheck.append("phoneNumber", phoneNumber);
      formDataCheck.append("registeredBusiness", registeredBusiness);
      formDataCheck.append("organizationName", organizationName);
      formDataCheck.append("organizationPhoneNumber", organizationPhoneNumber);
      formDataCheck.append("organizationBusinessTime", organizationBusinessTime);
      formDataCheck.append("organizationAddress", organizationAddress);
      formDataCheck.append("psychiatristDetail", psychiatristDetail);
      formDataCheck.append("educationQualifications", educationQualifications);
      formDataCheck.append("yearsWorkingAsPsychiatrist", yearsWorkingAsDietitianInt);
      formDataCheck.append("everWorkedAsClinicalPsychiatrist", everWorkedAsClinicalPsychiatrist);
      formDataCheck.append("hospitalName", hospitalName);
      formDataCheck.append("state", state);
      formDataCheck.append("city", city);
      formDataCheck.append("yearsWorkingAtHospital", yearsWorkingAtHospital);
      formDataCheck.append("specialization", specialization);

      // For JSON objects, convert them to string before appending
      formDataCheck.append("nutritionConsultingForDiseases", JSON.stringify(formData));
      formDataCheck.append("psychiatristConsultingForDiseasesForClients", psychiatristConsultingForDiseasesForClients);
      formDataCheck.append("historyOfWorkingWithChild", historyOfWorkingWithChild);
      formDataCheck.append("detailsOfMethodologyOrDietaryGuidelinesFollowed", detailsOfMethodologyOrDietaryGuidelinesFollowed);
      formDataCheck.append("genderPreference", genderPreference);
      formDataCheck.append("childrenFromBirthTillThreeYearsAge", childrenFromBirthTillThreeYearsAge);
      formDataCheck.append("childrenFromTwoToTwelveYearsAge", childrenFromTwoToTwelveYearsAge);
      formDataCheck.append("childrenFromThirteenToTwentyYearsAge", childrenFromThirteenToTwentyYearsAge);
      formDataCheck.append("adultMaleFromTwentyOneToSixtyYearsAge", adultMaleFromTwentyOneToSixtyYearsAge);
      formDataCheck.append("adultFemaleFromTwentyOneToSixtyYearsAge", adultFemaleFromTwentyOneToSixtyYearsAge);
      formDataCheck.append("seniorsOfAgeGreaterThenSixty", seniorsOfAgeGreaterThenSixty);
      formDataCheck.append("outstandingTestimonials", testimonials);
      formDataCheck.append("sharingReferences", sharingReferences);

      // For arrays or objects, JSON.stringify them before appending
      formDataCheck.append("referenceDetail", JSON.stringify(referenceDetail));
      formDataCheck.append("preferredDayAndTime", JSON.stringify(preferredDayAndTime));

      // For files, append them directly
      // files.forEach((file, index) => {
      //   formDataCheck.append(`files`, file);
      // });

      // testimonial.forEach((file, index) => {
      //   formDataCheck.append(`testimonialFiles`, file);
      // });
      try {
        const response = await AxiosInstance.post("psychiatrist/add-psychiatrist", formDataCheck, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        Swal.fire({
          icon: "success",
          text: "Form data submitted successfully",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        toast.error("Failed to submit information", error);
      }
    }
  };

  const getDietitianData = () => {
    AxiosInstance.get("psychiatrist/getPsychiatrist")
      .then((response) => {
        setFirstName(response?.firstName);
        setLastName(response?.lastName);
        setEmail(response?.email);
        setPhoneNumber(response?.phoneNumber);
        setRegisteredBusiness(response?.registeredBusiness);
        setOrganizationAddress(response?.organizationAddress);
        setOrganizationBusinessTime(response?.organizationBusinessTime);
        setOrganizationName(response?.organizationName);
        setOrganizationPhoneNumber(response?.organizationPhoneNumber);
        setPsychiatristDetail(response?.psychiatristDetail);
        setEducationQualifications(response?.educationQualifications);
        setYearsWorkingAsPsychiatrist(response?.yearsWorkingAsPsychiatrist);
        setEverWorkedAsClinicalPsychiatrist(response?.everWorkedAsClinicalPsychiatrist);
        setHospitalName(response?.hospitalName);
        setState(response?.state);
        setCity(response?.city);
        setYearsWorkingAtHospital(response?.yearsWorkingAtHospital);
        setSpecialization(response?.specialization);
        if (response?.psychiatristConsultingForDiseasesForClients) {
          const diseasesArray = response.psychiatristConsultingForDiseasesForClients.split(",");

          // Trim and convert each disease to lowercase for consistent comparison
          const trimmedDiseasesArray = diseasesArray.map((disease) => disease.trim().toLowerCase());

          // Predefined diseases also need to be in lowercase for case-insensitive comparison
          const predefinedDiseasesLowerCase = predefinedDiseases.map((disease) => disease.toLowerCase());

          // Separate predefined diseases and others
          const predefined = trimmedDiseasesArray.filter((disease) => predefinedDiseasesLowerCase.includes(disease));

          const others = trimmedDiseasesArray.filter((disease) => !predefinedDiseasesLowerCase.includes(disease));

          // Convert back to the original casing by mapping the predefined diseases
          const predefinedDiseasesMapped = predefinedDiseases.filter((disease) => predefined.includes(disease.toLowerCase()));

          // Set predefined diseases back to the main state
          setPsychiatristConsultingForDiseasesForClients(predefinedDiseasesMapped.join(","));

          // Set other diseases if there are any
          if (others.length > 0) {
            setDiseasesOtherValue(others.join(","));
          } else {
            setDiseasesOtherValue(""); // Clear "Other" field if no other diseases
          }
        }
        setHistoryOfWorkingWithChild(response?.historyOfWorkingWithChild);
        setDetailsOfMethodologyOrDietaryGuidelinesFollowed(response?.detailsOfMethodologyOrDietaryGuidelinesFollowed);
        setGenderPreference(response?.genderPreference);
        setChildrenFromBirthTillThreeYearsAge(response?.childrenFromBirthTillThreeYearsAge);
        setChildrenFromTwoToTwelveYearsAge(response?.childrenFromTwoToTwelveYearsAge);
        setChildrenFromThirteenToTwentyYearsAge(response?.childrenFromThirteenToTwentyYearsAge);
        setAdultMaleFromTwentyOneToSixtyYearsAge(response?.adultMaleFromTwentyOneToSixtyYearsAge);
        setAdultFemaleFromTwentyOneToSixtyYearsAgee(response?.adultFemaleFromTwentyOneToSixtyYearsAge);
        setSeniorsOfAgeGreaterThenSixty(response?.seniorsOfAgeGreaterThenSixty);
        setSharingReferences(response?.sharingReferences);
        setPreferredDayAndTime(response?.preferredDayAndTime ? JSON.parse(response?.preferredDayAndTime) : preferredDayAndTime);
        setReferenceDetail(JSON.parse(response?.referenceDetail));
        setFormData(response?.nutritionConsultingForDiseases ? JSON.parse(response?.nutritionConsultingForDiseases) : formData);
        setTestimonials(response?.outstandingTestimonials);
        setOutstandingTestimonialsFileList(response?.outstandingTestimonialsFileList || []);
        setDocumentsFileList(response?.documentsFileList || []);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getDietitianData();
  }, []);
  // const AttachTestimonial = async (event) => {
  //   event.preventDefault();
  //   const newFiles = Array.from(event.target.files); // Get the uploaded files
  //   setTestimonial((prevFiles) => [...prevFiles, ...newFiles]); // Add the new files to the existing files array

  //   // const formData = new FormData();
  //   // for (let i = 0; i < newFiles.length; i++) {
  //   //   formData.append("testimonialFiles[]", newFiles[i]); // Append each file to the form data
  //   // }
  // };

  const AttachTestimonial = async (event) => {
    event.preventDefault();

    const fileArray = Array.from(event.target.files);
    console.log("file: ", fileArray);

    // Ensure files are not empty
    if (fileArray.length === 0) {
      toast.error("No files selected.");
      return;
    }

    let fileCheck = fileArray[0]?.name?.split(".")?.pop();
    if (fileCheck === "gif" || fileCheck === "jfif" || fileCheck === "csv" || fileCheck === "xls") {
      toast.error("GIF, CSV, XLS, and JFIF files are not allowed");
      return;
    }
    setSelectedTestimonialFiles([...selectedTestimonialFiles, ...fileArray]);
    const formData = new FormData();

    // Append files to formData
    formData.append("fileType", "Testimonial");
    for (const file of fileArray) {
      formData.append("file", file); // Ensure the key matches backend expectations
    }
    settestimonialfileUploading(true);
    try {
      const response = await AxiosInstance.post("psychiatrist/psychiatrist-files", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Documents Uploaded Successfully");
      settestimonialfileUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the files.");
      settestimonialfileUploading(false);
    }
  };

  const handleAttachCopies = async (event) => {
    event.preventDefault();

    const fileArray = Array.from(event.target.files);
    console.log("file: ", fileArray);

    // Ensure files are not empty
    if (fileArray.length === 0) {
      toast.error("No files selected.");
      return;
    }

    let fileCheck = fileArray[0]?.name?.split(".")?.pop();
    if (fileCheck === "gif" || fileCheck === "jfif" || fileCheck === "csv" || fileCheck === "xls") {
      toast.error("GIF, CSV, XLS, and JFIF files are not allowed");
      return;
    }
    setSelectedFiles([...selectedFiles, ...fileArray]);
    const formData = new FormData();

    // Append files to formData
    formData.append("fileType", "Documents");
    for (const file of fileArray) {
      formData.append("file", file);
    }
    setfileUploading(true);
    try {
      const response = await AxiosInstance.post("psychiatrist/psychiatrist-files", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Documents Uploaded Successfully");
      setfileUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the files.");
      setfileUploading(false);
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const addTestimonial = () => {
    if (testimonials?.length < 3) {
      setTestimonials([...testimonials, ""]);
    }
  };

  const handleInputChange = (index, value) => {
    const newTestimonials = [...testimonials];
    newTestimonials[index] = value;
    setTestimonials(newTestimonials);
  };

  useEffect(() => {
    if (testimonials?.length >= 3) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [testimonials]);

  const isChecked = (category, value) => {
    return (formData && category && value && formData[category]?.ageGroup?.includes(value)) || false;
  };

  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">First Name of Clinical Psychiatrist</Label>
              {Object.keys(selectedUser).length === 0 ? (
                <Input
                  disabled
                  id=""
                  name="name"
                  value={firstName}
                  type="text"
                />
              ) : (
                <Input
                  disabled
                  id=""
                  name="name"
                  value={selectedUser?.selectedUser?.firstName}
                  type="text"
                />
              )}
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">Last Name of Clinical Psychiatrist</Label>
              {Object.keys(selectedUser).length === 0 ? (
                <Input
                  id=""
                  disabled
                  name="name"
                  value={lastName}
                  type="text"
                />
              ) : (
                <Input
                  disabled
                  id=""
                  name="name"
                  value={selectedUser?.selectedUser?.lastName}
                  type="text"
                />
              )}
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="examplePassword">Email</Label>
              <Input
                disabled
                id=""
                name="email"
                value={email}
                type="email"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">Phone Number</Label>
              <Input
                disabled
                id="phoneNumber"
                name="registrationNumber"
                value={phoneNumber}
                type="number"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span
                style={{
                  color: fieldErrors?.registeredBusiness ? "red" : "inherit",
                }}
              >
                Do you have a registered practice/organization for your business ?
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                value="true"
                checked={registeredBusiness === true}
                onChange={(e) => {
                  handleRegisteredBusiness(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                value="false"
                checked={registeredBusiness === false}
                onChange={(e) => {
                  handleRegisteredBusiness(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(showRegisteredOrganization || registeredBusiness) && (
          <>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="organizationName">Name</Label>
                  <Input
                    id={`organizationName`}
                    name="organizationName"
                    placeholder="Enter Name"
                    type="text"
                    value={organizationName}
                    onChange={(e) => {
                      handleOrganizationName(e);
                    }}
                    style={{
                      borderColor: fieldErrors?.organizationName ? "red" : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`phoneNumber`}>Phone Number</Label>
                  <Input
                    id={`phoneNumber`}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    maxLength={10}
                    onChange={(e) => {
                      handleOrganizationPhoneNumber(e);
                    }}
                    value={organizationPhoneNumber}
                    style={{
                      borderColor: fieldErrors?.organizationPhoneNumber ? "red" : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`organizationBusinessTime`}>Business Time</Label>
                  <Input
                    id={`organizationBusinessTime`}
                    name="organizationBusinessTime"
                    placeholder="Enter Email Id"
                    type="time"
                    onChange={(e) => {
                      handleOrganizationBusinessTime(e);
                    }}
                    value={organizationBusinessTime}
                    style={{
                      borderColor: fieldErrors?.organizationBusinessTime ? "red" : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for={`organizationAddress`}>Address</Label>
                  <Input
                    id={`organizationAddress`}
                    name="phoneNumber"
                    placeholder="Enter Address"
                    type="textarea"
                    onChange={(e) => {
                      handleOrganizationAddress(e);
                    }}
                    value={organizationAddress}
                    style={{
                      borderColor: fieldErrors?.organizationAddress ? "red" : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label for="exampleEmail">Please narrate briefly about yourself.</Label>
              <Input
                id=""
                name="name"
                value={psychiatristDetail}
                onChange={(e) => {
                  handleDietitianDetail(e);
                }}
                placeholder="Narrate about yourself"
                type="textarea"
                style={{
                  borderColor: fieldErrors?.psychiatristDetail ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="exampleEmail">List all your educational qualifications as a Psychiatrist.</Label>
              <Input
                id=""
                name="name"
                value={educationQualifications}
                onChange={(e) => {
                  handleEducationQualification(e);
                }}
                placeholder="List your educational qualification"
                type="textarea"
                style={{
                  borderColor: fieldErrors?.educationQualifications ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={4}>How long (years) you have now worked in this capacity?</Col>
          <Col md={4}>
            <FormGroup>
              <Input
                id=""
                name="name"
                value={yearsWorkingAsPsychiatrist}
                onChange={(e) => {
                  handleYearsWorkingAsDietitian(e);
                }}
                placeholder="Enter years"
                type="number"
                style={{
                  borderColor: fieldErrors?.yearsWorkingAsPsychiatrist ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span
                style={{
                  color: fieldErrors?.everWorkedAsClinicalPsychiatrist ? "red" : "inherit",
                }}
              >
                Have you ever worked as a Psychiatrist in a hospital or clinic setting dealing directly with patients and taking care of their dietary needs after illness?
              </span>{" "}
              &nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalPsychiatrist"
                type="radio"
                value="true"
                checked={everWorkedAsClinicalPsychiatrist === true}
                onChange={(e) => {
                  handleeverWorkedAsClinicalDietitian(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalPsychiatrist"
                type="radio"
                value="false"
                checked={everWorkedAsClinicalPsychiatrist === false}
                onChange={(e) => {
                  handleeverWorkedAsClinicalDietitian(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(showEverWorkedasClicnicalAdvisor || everWorkedAsClinicalPsychiatrist) && (
          <>
            <Row></Row>
            <Row>
              <Col md={4}>
                <Label>Name of Hospital / Clinic</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={hospitalName}
                    onChange={(e) => {
                      handleHospitalName(e);
                    }}
                    placeholder="Enter name of hospital / clinic"
                    type="text"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.hospitalName ? "red" : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>State</Label>
                <FormGroup>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    value={state}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.state ? "red" : "inherit",
                    }}
                  >
                    <option value="">Select State</option>
                    {states.map((state, index) => (
                      <option
                        key={index}
                        value={state}
                      >
                        {state}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>City</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={city}
                    onChange={(e) => {
                      handleCity(e);
                    }}
                    placeholder="Enter City"
                    type="text"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.city ? "red" : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>How many years? </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="yearsWorkingAtHospital"
                    value={yearsWorkingAtHospital}
                    onChange={(e) => {
                      handleHowManyYears(e);
                    }}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.yearsWorkingAtHospital ? "red" : "inherit",
                    }}
                  >
                    <option value="">Select years</option>
                    <option value="less than 1 year">Less than 1 year</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="3-4 years">3-4 years</option>
                    <option value="5 years or more">5 years or more</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>Which Specialization : </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="specialization"
                    onChange={(e) => {
                      handleSpecialization(e);
                    }}
                    value={specialization}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.specialization ? "red" : "inherit",
                    }}
                  >
                    <option value="">Select Specialization</option>
                    <option value="CVD">CVD</option>
                    <option value="hypertension">BipolarDisorder</option>
                    <option value="obesity/weightLoss">Obesity / Weight Loss</option>
                    <option value="GeneralizedAnxietyDisorder">GeneralizedAnxietyDisorder</option>
                    <option value="PanicDisorder">PanicDisorder</option>
                    <option value="SocialAnxietyDisorder">SocialAnxietyDisorder</option>
                    <option value="SpecificPhobias">SpecificPhobias</option>
                    <option value="Oral Cancer ">Oral Cancer </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label>For which of the following diseases, you have provided or currently providing Psychiatrist?</Label>
            </FormGroup>
          </Col>
        </Row>

        <TableContainer
          sx={{ border: "1px solid black", borderRadius: "5px" }}
          elevation={5}
          className="mt-3 mb-3"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Disease
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  Gender
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  Age Group
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  How many years
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  How many patients/people you have helped so far? (Approx No.)
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Ever Worked with Seniors ?
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Depression</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="depressionGender"
                    value={formData?.Depression?.gender}
                    onChange={(e) => {
                      handleGenderChange("Depression", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.Depression?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("Depression", "0-3")}
                              sx={{
                                color: fieldErrors?.Depression?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Depression?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          // checked={formData?.CVD.ageGroup}
                          onChange={(e) => {
                            handleAgeGroupChange("Depression", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("Depression", "3-13")}
                              sx={{
                                color: fieldErrors?.Depression?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Depression?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Depression", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("Depression", "13-21")}
                              sx={{
                                color: fieldErrors?.Depression?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Depression?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Depression", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("Depression", ">21")}
                              sx={{
                                color: fieldErrors?.Depression?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Depression?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Depression", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Depression?.years}
                    onChange={(e) => {
                      handleYearsChange("Depression", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Depression?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Depression?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Depression", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Depression?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_depression"
                          value="yes"
                          checked={formData?.Depression?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("Depression", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.Depression?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_depression"
                          value="no"
                          checked={formData?.Depression?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("Depression", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.Depression?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Bipolar Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="BipolarDisorder"
                    value={formData?.BipolarDisorder?.gender}
                    onChange={(e) => {
                      handleGenderChange("BipolarDisorder", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.BipolarDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("BipolarDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BipolarDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("BipolarDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BipolarDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("BipolarDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BipolarDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("BipolarDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BipolarDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BipolarDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.BipolarDisorder?.years}
                    onChange={(e) => {
                      handleYearsChange("BipolarDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.BipolarDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.BipolarDisorder?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("BipolarDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.BipolarDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_BipolarDisorder"
                          value="yes"
                          checked={formData?.BipolarDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("BipolarDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.BipolarDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_BipolarDisorder"
                          value="no"
                          checked={formData?.BipolarDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("BipolarDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.BipolarDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Cyclothymic Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="CyclothymicDisorder"
                    onChange={(e) => {
                      handleGenderChange("CyclothymicDisorder", e.target.value);
                    }}
                    value={formData?.["CyclothymicDisorder"]?.gender}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.CyclothymicDisorder?.gender ? "red" : "lightgrey",
                    }}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("CyclothymicDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CyclothymicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("CyclothymicDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CyclothymicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("CyclothymicDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CyclothymicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("CyclothymicDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CyclothymicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CyclothymicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["CyclothymicDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("CyclothymicDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="text"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.CyclothymicDisorder?.years ? "red" : "lightgrey",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["CyclothymicDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("CyclothymicDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="text"
                    style={{
                      borderColor: fieldErrors?.CyclothymicDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_CyclothymicDisorder"
                          value="yes"
                          checked={formData?.CyclothymicDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("CyclothymicDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.CyclothymicDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_CyclothymicDisorder"
                          value="no"
                          checked={formData?.CyclothymicDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("CyclothymicDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.CyclothymicDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Generalized Anxiety Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="GeneralizedAnxietyDisorder"
                    onChange={(e) => {
                      handleGenderChange("GeneralizedAnxietyDisorder", e.target.value);
                    }}
                    value={formData?.GeneralizedAnxietyDisorder?.gender}
                    style={{
                      borderColor: fieldErrors?.GeneralizedAnxietyDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("GeneralizedAnxietyDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("GeneralizedAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("GeneralizedAnxietyDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("GeneralizedAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("GeneralizedAnxietyDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("GeneralizedAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("GeneralizedAnxietyDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.GeneralizedAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("GeneralizedAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.GeneralizedAnxietyDisorder?.years}
                    onChange={(e) => {
                      handleYearsChange("GeneralizedAnxietyDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    style={{
                      borderColor: fieldErrors?.GeneralizedAnxietyDisorder?.years ? "red" : "lightgrey",
                    }}
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.GeneralizedAnxietyDisorder?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("GeneralizedAnxietyDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.GeneralizedAnxietyDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_GeneralizedAnxietyDisorder"
                          value="yes"
                          checked={formData?.GeneralizedAnxietyDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("GeneralizedAnxietyDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.GeneralizedAnxietyDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_GeneralizedAnxietyDisorder"
                          value="no"
                          checked={formData?.GeneralizedAnxietyDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("GeneralizedAnxietyDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.GeneralizedAnxietyDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Panic Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="PanicDisorder"
                    onChange={(e) => {
                      handleGenderChange("PanicDisorder", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.PanicDisorder?.gender ? "red" : "lightgrey",
                    }}
                    value={formData?.["PanicDisorder"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("PanicDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PanicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("PanicDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PanicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("PanicDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PanicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("PanicDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PanicDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PanicDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["PanicDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("PanicDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.PanicDisorder?.years ? "red" : "lightgrey",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["PanicDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("PanicDisorder", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.PanicDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_PanicDisorder"
                          value="yes"
                          checked={formData?.PanicDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("PanicDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.PanicDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_PanicDisorder"
                          value="no"
                          checked={formData?.PanicDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("PanicDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.PanicDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Social Anxiety Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="SocialAnxietyDisorder"
                    onChange={(e) => {
                      handleGenderChange("SocialAnxietyDisorder", e.target.value);
                    }}
                    value={formData?.["SocialAnxietyDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.SocialAnxietyDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("SocialAnxietyDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SocialAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("SocialAnxietyDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SocialAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("SocialAnxietyDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SocialAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("SocialAnxietyDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SocialAnxietyDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SocialAnxietyDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SocialAnxietyDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("SocialAnxietyDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.SocialAnxietyDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SocialAnxietyDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("SocialAnxietyDisorder", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.SocialAnxietyDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SocialAnxietyDisorder"
                          value="yes"
                          checked={formData?.SocialAnxietyDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SocialAnxietyDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SocialAnxietyDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SocialAnxietyDisorder"
                          value="no"
                          checked={formData?.SocialAnxietyDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SocialAnxietyDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SocialAnxietyDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Specific Phobias</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="SpecificPhobias"
                    onChange={(e) => {
                      handleGenderChange("SpecificPhobias", e.target.value);
                    }}
                    value={formData?.["SpecificPhobias"]?.gender}
                    style={{
                      borderColor: fieldErrors?.SpecificPhobias?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("SpecificPhobias", "0-3")}
                              sx={{
                                color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SpecificPhobias", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("SpecificPhobias", "3-13")}
                              sx={{
                                color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SpecificPhobias", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("SpecificPhobias", "13-21")}
                              sx={{
                                color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SpecificPhobias", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("SpecificPhobias", ">21")}
                              sx={{
                                color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SpecificPhobias?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SpecificPhobias", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SpecificPhobias"]?.years}
                    onChange={(e) => {
                      handleYearsChange("SpecificPhobias", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.SpecificPhobias?.years ? "red" : "lightgrey",
                    }}
                    placeholder="Enter years"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SpecificPhobias"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("SpecificPhobias", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.SpecificPhobias?.patientsHelped ? "red" : "lightgrey",
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SpecificPhobias"
                          value="yes"
                          checked={formData?.SpecificPhobias?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SpecificPhobias", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SpecificPhobias?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SpecificPhobias"
                          value="no"
                          checked={formData?.SpecificPhobias?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SpecificPhobias", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SpecificPhobias?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Obsessive-Compulsive Disorder (OCD)</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="ObsessiveCompulsiveDisorder"
                    onChange={(e) => {
                      handleGenderChange("ObsessiveCompulsiveDisorder", e.target.value);
                    }}
                    value={formData?.["ObsessiveCompulsiveDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.ObsessiveCompulsiveDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("ObsessiveCompulsiveDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("ObsessiveCompulsiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("ObsessiveCompulsiveDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("ObsessiveCompulsiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("ObsessiveCompulsiveDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("ObsessiveCompulsiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("ObsessiveCompulsiveDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObsessiveCompulsiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("ObsessiveCompulsiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ObsessiveCompulsiveDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("ObsessiveCompulsiveDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.ObsessiveCompulsiveDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ObsessiveCompulsiveDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("ObsessiveCompulsiveDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.ObsessiveCompulsiveDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_ObsessiveCompulsiveDisorder"
                          value="yes"
                          checked={formData?.ObsessiveCompulsiveDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("ObsessiveCompulsiveDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.ObsessiveCompulsiveDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_ObsessiveCompulsiveDisorder"
                          value="no"
                          checked={formData?.ObsessiveCompulsiveDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("ObsessiveCompulsiveDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.ObsessiveCompulsiveDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Post-Traumatic Stress Disorder (PTSD)</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="PostTraumaticStressDisorder"
                    onChange={(e) => {
                      handleGenderChange("PostTraumaticStressDisorder", e.target.value);
                    }}
                    value={formData?.["PostTraumaticStressDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.PostTraumaticStressDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("PostTraumaticStressDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PostTraumaticStressDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("PostTraumaticStressDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PostTraumaticStressDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("PostTraumaticStressDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PostTraumaticStressDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("PostTraumaticStressDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.PostTraumaticStressDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("PostTraumaticStressDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["PostTraumaticStressDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("PostTraumaticStressDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.PostTraumaticStressDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["PostTraumaticStressDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("PostTraumaticStressDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.PostTraumaticStressDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_PostTraumaticStressDisorder"
                          value="yes"
                          checked={formData?.PostTraumaticStressDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("PostTraumaticStressDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.PostTraumaticStressDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_PostTraumaticStressDisorder"
                          value="no"
                          checked={formData?.PostTraumaticStressDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("PostTraumaticStressDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.PostTraumaticStressDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Schizophrenia</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="Schizophrenia"
                    onChange={(e) => {
                      handleGenderChange("Schizophrenia", e.target.value);
                    }}
                    value={formData?.["Schizophrenia"]?.gender}
                    style={{
                      borderColor: fieldErrors?.Schizophrenia?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("Schizophrenia", "0-3")}
                              sx={{
                                color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Schizophrenia", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("Schizophrenia", "3-13")}
                              sx={{
                                color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Schizophrenia", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("Schizophrenia", "13-21")}
                              sx={{
                                color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Schizophrenia", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("Schizophrenia", ">21")}
                              sx={{
                                color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Schizophrenia?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Schizophrenia", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Schizophrenia"]?.years}
                    onChange={(e) => {
                      handleYearsChange("Schizophrenia", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Schizophrenia?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["Schizophrenia"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Schizophrenia", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Schizophrenia?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_Schizophrenia"
                          value="yes"
                          checked={formData?.Schizophrenia?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("Schizophrenia", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.Schizophrenia?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_Schizophrenia"
                          value="no"
                          checked={formData?.Schizophrenia?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("Schizophrenia", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.Schizophrenia?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Schizoaffective Disorder:</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                <Input
                    type="select"
                    id=""
                    name="SchizoaffectiveDisorder"
                    onChange={(e) => {
                      handleGenderChange("SchizoaffectiveDisorder", e.target.value);
                    }}
                    value={formData?.["SchizoaffectiveDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.SchizoaffectiveDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("SchizoaffectiveDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SchizoaffectiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("SchizoaffectiveDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SchizoaffectiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("SchizoaffectiveDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SchizoaffectiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("SchizoaffectiveDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SchizoaffectiveDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SchizoaffectiveDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SchizoaffectiveDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("SchizoaffectiveDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.SchizoaffectiveDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SchizoaffectiveDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("SchizoaffectiveDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.SchizoaffectiveDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SchizoaffectiveDisorder"
                          value="yes"
                          checked={formData?.SchizoaffectiveDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SchizoaffectiveDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SchizoaffectiveDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SchizoaffectiveDisorder"
                          value="no"
                          checked={formData?.SchizoaffectiveDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SchizoaffectiveDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SchizoaffectiveDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Brief Psychotic Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="BriefPsychoticDisorder"
                    onChange={(e) => {
                      handleGenderChange("BriefPsychoticDisorder", e.target.value);
                    }}
                    value={formData?.["BriefPsychoticDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.BriefPsychoticDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("BriefPsychoticDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BriefPsychoticDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("BriefPsychoticDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BriefPsychoticDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("BriefPsychoticDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BriefPsychoticDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("BriefPsychoticDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BriefPsychoticDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BriefPsychoticDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["BriefPsychoticDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("BriefPsychoticDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.BriefPsychoticDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["BriefPsychoticDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("BriefPsychoticDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.BriefPsychoticDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_BriefPsychoticDisorder"
                          value="yes"
                          checked={formData?.BriefPsychoticDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("BriefPsychoticDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.BriefPsychoticDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_BriefPsychoticDisorder"
                          value="no"
                          checked={formData?.BriefPsychoticDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("BriefPsychoticDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.BriefPsychoticDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Delusional Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="DelusionalDisorder"
                    onChange={(e) => {
                      handleGenderChange("DelusionalDisorder", e.target.value);
                    }}
                    value={formData?.["DelusionalDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.DelusionalDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("DelusionalDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("DelusionalDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("DelusionalDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("DelusionalDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("DelusionalDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("DelusionalDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("DelusionalDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.DelusionalDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("DelusionalDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["DelusionalDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("DelusionalDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.DelusionalDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["DelusionalDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("DelusionalDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.DelusionalDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_DelusionalDisorder"
                          value="yes"
                          checked={formData?.DelusionalDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("DelusionalDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.DelusionalDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_DelusionalDisorder"
                          value="no"
                          checked={formData?.DelusionalDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("DelusionalDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.DelusionalDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Borderline Personality Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="BorderlinePersonalityDisorder"
                    onChange={(e) => {
                      handleGenderChange("BorderlinePersonalityDisorder", e.target.value);
                    }}
                    value={formData?.["BorderlinePersonalityDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.BorderlinePersonalityDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("BorderlinePersonalityDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BorderlinePersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("BorderlinePersonalityDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BorderlinePersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("BorderlinePersonalityDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BorderlinePersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("BorderlinePersonalityDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.BorderlinePersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("BorderlinePersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["BorderlinePersonalityDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("BorderlinePersonalityDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.BorderlinePersonalityDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["BorderlinePersonalityDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("BorderlinePersonalityDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.BorderlinePersonalityDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_BorderlinePersonalityDisorder"
                          value="yes"
                          checked={formData?.BorderlinePersonalityDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("BorderlinePersonalityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.BorderlinePersonalityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_BorderlinePersonalityDisorder"
                          value="no"
                          checked={formData?.BorderlinePersonalityDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("BorderlinePersonalityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.BorderlinePersonalityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Antisocial Personality Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="AntisocialPersonalityDisorder"
                    onChange={(e) => {
                      handleGenderChange("AntisocialPersonalityDisorder", e.target.value);
                    }}
                    value={formData?.["AntisocialPersonalityDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.AntisocialPersonalityDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("AntisocialPersonalityDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AntisocialPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("AntisocialPersonalityDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AntisocialPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("AntisocialPersonalityDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AntisocialPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("AntisocialPersonalityDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AntisocialPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AntisocialPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["AntisocialPersonalityDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("AntisocialPersonalityDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.AntisocialPersonalityDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["AntisocialPersonalityDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("AntisocialPersonalityDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.AntisocialPersonalityDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_AntisocialPersonalityDisorder"
                          value="yes"
                          checked={formData?.AntisocialPersonalityDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("AntisocialPersonalityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.AntisocialPersonalityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_AntisocialPersonalityDisorder"
                          value="no"
                          checked={formData?.AntisocialPersonalityDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("AntisocialPersonalityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.AntisocialPersonalityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Narcissistic Personality Disorder</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="NarcissisticPersonalityDisorder"
                    onChange={(e) => {
                      handleGenderChange("NarcissisticPersonalityDisorder", e.target.value);
                    }}
                    value={formData?.["NarcissisticPersonalityDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.NarcissisticPersonalityDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("NarcissisticPersonalityDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("NarcissisticPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("NarcissisticPersonalityDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("NarcissisticPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("NarcissisticPersonalityDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("NarcissisticPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("NarcissisticPersonalityDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.NarcissisticPersonalityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("NarcissisticPersonalityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["NarcissisticPersonalityDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("NarcissisticPersonalityDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.NarcissisticPersonalityDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["NarcissisticPersonalityDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("NarcissisticPersonalityDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.NarcissisticPersonalityDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_NarcissisticPersonalityDisorder"
                          value="yes"
                          checked={formData?.NarcissisticPersonalityDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("NarcissisticPersonalityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.NarcissisticPersonalityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_NarcissisticPersonalityDisorder"
                          value="no"
                          checked={formData?.NarcissisticPersonalityDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("NarcissisticPersonalityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.NarcissisticPersonalityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Eating Disorders</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="EatingDisorders"
                    onChange={(e) => {
                      handleGenderChange("EatingDisorders", e.target.value);
                    }}
                    value={formData?.["EatingDisorders"]?.gender}
                    style={{
                      borderColor: fieldErrors?.EatingDisorders?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("EatingDisorders", "0-3")}
                              sx={{
                                color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("EatingDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("EatingDisorders", "3-13")}
                              sx={{
                                color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("EatingDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("EatingDisorders", "13-21")}
                              sx={{
                                color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("EatingDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("EatingDisorders", ">21")}
                              sx={{
                                color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.EatingDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("EatingDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["EatingDisorders"]?.years}
                    onChange={(e) => {
                      handleYearsChange("EatingDisorders", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.EatingDisorders?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["EatingDisorders"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("EatingDisorders", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.EatingDisorders?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_EatingDisorders"
                          value="yes"
                          checked={formData?.EatingDisorders?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("EatingDisorders", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.EatingDisorders?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_EatingDisorders"
                          value="no"
                          checked={formData?.EatingDisorders?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("EatingDisorders", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.EatingDisorders?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Substance Use Disorders</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="SubstanceUseDisorders"
                    onChange={(e) => {
                      handleGenderChange("SubstanceUseDisorders", e.target.value);
                    }}
                    value={formData?.["SubstanceUseDisorders"]?.gender}
                    style={{
                      borderColor: fieldErrors?.SubstanceUseDisorders?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("SubstanceUseDisorders", "0-3")}
                              sx={{
                                color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SubstanceUseDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("SubstanceUseDisorders", "3-13")}
                              sx={{
                                color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SubstanceUseDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("SubstanceUseDisorders", "13-21")}
                              sx={{
                                color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SubstanceUseDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("SubstanceUseDisorders", ">21")}
                              sx={{
                                color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.SubstanceUseDisorders?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("SubstanceUseDisorders", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SubstanceUseDisorders"]?.years}
                    onChange={(e) => {
                      handleYearsChange("SubstanceUseDisorders", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.SubstanceUseDisorders?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["SubstanceUseDisorders"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("SubstanceUseDisorders", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.SubstanceUseDisorders?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SubstanceUseDisorders"
                          value="yes"
                          checked={formData?.SubstanceUseDisorders?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SubstanceUseDisorders", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SubstanceUseDisorders?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_SubstanceUseDisorders"
                          value="no"
                          checked={formData?.SubstanceUseDisorders?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("SubstanceUseDisorders", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.SubstanceUseDisorders?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Attention-Deficit/Hyperactivity Disorder (ADHD)</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="AttentionDeficitOrHyperactivityDisorder"
                    onChange={(e) => {
                      handleGenderChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                    }}
                    value={formData?.["AttentionDeficitOrHyperactivityDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("AttentionDeficitOrHyperactivityDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("AttentionDeficitOrHyperactivityDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("AttentionDeficitOrHyperactivityDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("AttentionDeficitOrHyperactivityDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["AttentionDeficitOrHyperactivityDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["AttentionDeficitOrHyperactivityDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("AttentionDeficitOrHyperactivityDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_AttentionDeficitOrHyperactivityDisorder"
                          value="yes"
                          checked={formData?.AttentionDeficitOrHyperactivityDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("AttentionDeficitOrHyperactivityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_AttentionDeficitOrHyperactivityDisorder"
                          value="no"
                          checked={formData?.AttentionDeficitOrHyperactivityDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("AttentionDeficitOrHyperactivityDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.AttentionDeficitOrHyperactivityDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Autism Spectrum Disorder (ASD)</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="AutismSpectrumDisorder"
                    onChange={(e) => {
                      handleGenderChange("AutismSpectrumDisorder", e.target.value);
                    }}
                    value={formData?.["AutismSpectrumDisorder"]?.gender}
                    style={{
                      borderColor: fieldErrors?.AutismSpectrumDisorder?.gender ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("AutismSpectrumDisorder", "0-3")}
                              sx={{
                                color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AutismSpectrumDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("AutismSpectrumDisorder", "3-13")}
                              sx={{
                                color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AutismSpectrumDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("AutismSpectrumDisorder", "13-21")}
                              sx={{
                                color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AutismSpectrumDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("AutismSpectrumDisorder", ">21")}
                              sx={{
                                color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.AutismSpectrumDisorder?.ageGroup ? "red" : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("AutismSpectrumDisorder", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["AutismSpectrumDisorder"]?.years}
                    onChange={(e) => {
                      handleYearsChange("AutismSpectrumDisorder", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.AutismSpectrumDisorder?.years ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["AutismSpectrumDisorder"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("AutismSpectrumDisorder", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.AutismSpectrumDisorder?.patientsHelped ? "red" : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_AutismSpectrumDisorder"
                          value="yes"
                          checked={formData?.AutismSpectrumDisorder?.workedWithSeniors === "yes"}
                          onChange={(e) => handleWorkedWithSeniorsChange("AutismSpectrumDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.AutismSpectrumDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="workedWithSeniors_AutismSpectrumDisorder"
                          value="no"
                          checked={formData?.AutismSpectrumDisorder?.workedWithSeniors === "no"}
                          onChange={(e) => handleWorkedWithSeniorsChange("AutismSpectrumDisorder", e.target.value)}
                          style={{
                            borderColor: fieldErrors?.AutismSpectrumDisorder?.workedWithSeniors ? "red" : "lightgrey",
                          }}
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>Are you comfortable working with children of all ages, guiding them towards a healthier and better lifestyle. Please elaborate your work with children in the past (if any).</span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={historyOfWorkingWithChild}
              onChange={(e) => {
                handleHistoryOfWorkingWithChild(e);
              }}
              placeholder="Elaborate your work with children in the past"
              type="textarea"
              style={{
                borderColor: fieldErrors?.historyOfWorkingWithChild ? "red" : "lightgrey",
              }}
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>Do you follow any specific methodology or national/international dietary guidelines while providing diet consultation? Please explain briefly your approach.</span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={detailsOfMethodologyOrDietaryGuidelinesFollowed}
              onChange={(e) => {
                handleDetailsOfMethodologyOrDietaryGuidelinesFollowed(e);
              }}
              placeholder="Specific methodology or national/international dietary guidelines"
              type="textarea"
              style={{
                borderColor: fieldErrors?.detailsOfMethodologyOrDietaryGuidelinesFollowed ? "red" : "lightgrey",
              }}
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span
              style={{
                color: fieldErrors?.genderPreference ? "red" : "inherit",
              }}
            >
              Do you find yourself more comfortable working with specific gender (male vs female) or you are equally comfortable working with both gender?
            </span>
          </Col>
        </Row>
        <Row>
          {console.log("genderpre", genderPreference)}
          <Col md={6}>
            <FormGroup>
              <Input
                name="genderPreference"
                value="male"
                checked={genderPreference === "male"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Male
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="female"
                checked={genderPreference === "female"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Female
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="both"
                checked={genderPreference === "both"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Both
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Grid
          container
          spacing={2}
          mb={2}
        >
          <Grid item>
            <Label>
              Based on your past experiences, rate your own performance in terms of positive outcome for your clients, on a scale of 1 to 5 (5= Outstanding, 4= Excellent, 3= Good 2= Fair 1= Not Sure,
              0= No experience)
            </Label>
          </Grid>
        </Grid>

        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div style={{ display: "flex", gap: "85px" }}>
              <div>0</div>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
            </div>
          </Col>
        </Row>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors.childrenFromBirthTillThreeYearsAge ? "red" : "inherit",
                }}
              >
                1. Children (from Birth to 3 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="0-3"
                  value="0"
                  checked={childrenFromBirthTillThreeYearsAge === "0"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("0");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromBirthTillThreeYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="1"
                  checked={childrenFromBirthTillThreeYearsAge === "1"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("1");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromBirthTillThreeYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="2"
                  checked={childrenFromBirthTillThreeYearsAge === "2"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("2");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromBirthTillThreeYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="3"
                  checked={childrenFromBirthTillThreeYearsAge === "3"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("3");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromBirthTillThreeYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="4"
                  checked={childrenFromBirthTillThreeYearsAge === "4"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("4");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromBirthTillThreeYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="5"
                  checked={childrenFromBirthTillThreeYearsAge === "5"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("5");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromBirthTillThreeYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors.childrenFromTwoToTwelveYearsAge ? "red" : "inherit",
                }}
              >
                2. Children (2-12 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="2-12"
                  value="0"
                  checked={childrenFromTwoToTwelveYearsAge === "0"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("0");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromTwoToTwelveYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="1"
                  checked={childrenFromTwoToTwelveYearsAge === "1"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("1");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromTwoToTwelveYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="2"
                  checked={childrenFromTwoToTwelveYearsAge === "2"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("2");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromTwoToTwelveYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="3"
                  checked={childrenFromTwoToTwelveYearsAge === "3"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("3");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromTwoToTwelveYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="4"
                  checked={childrenFromTwoToTwelveYearsAge === "4"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("4");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromTwoToTwelveYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="5"
                  checked={childrenFromTwoToTwelveYearsAge === "5"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("5");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromTwoToTwelveYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.childrenFromThirteenToTwentyYearsAge ? "red" : "inherit",
                }}
              >
                3. Children (13-21 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="13-21"
                  value="0"
                  checked={childrenFromThirteenToTwentyYearsAge === "0"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("0");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromThirteenToTwentyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="1"
                  checked={childrenFromThirteenToTwentyYearsAge === "1"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("1");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromThirteenToTwentyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="2"
                  checked={childrenFromThirteenToTwentyYearsAge === "2"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("2");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromThirteenToTwentyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="3"
                  checked={childrenFromThirteenToTwentyYearsAge === "3"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("3");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromThirteenToTwentyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="4"
                  checked={childrenFromThirteenToTwentyYearsAge === "4"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("4");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromThirteenToTwentyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="5"
                  checked={childrenFromThirteenToTwentyYearsAge === "5"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("5");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      childrenFromThirteenToTwentyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.adultMaleFromTwentyOneToSixtyYearsAge ? "red" : "inherit",
                }}
              >
                4. Adults-Male (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60"
                  value="0"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "0"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("0");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultMaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="1"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "1"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("1");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultMaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="2"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "2"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("2");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultMaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="3"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "3"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("3");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultMaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="4"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "4"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("4");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultMaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="5"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "5"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("5");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultMaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.adultFemaleFromTwentyOneToSixtyYearsAge ? "red" : "inherit",
                }}
              >
                5. Adult-Female (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60F"
                  value="0"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "0"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("0");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultFemaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="1"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "1"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("1");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultFemaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="2"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "2"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("2");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultFemaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="3"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "3"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("3");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultFemaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="4"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "4"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("4");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultFemaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="5"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "5"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("5");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      adultFemaleFromTwentyOneToSixtyYearsAge: false,
                    }));
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.seniorsOfAgeGreaterThenSixty ? "red" : "inherit",
                }}
              >
                6. Seniors (Age &gt;60 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="<60"
                  value="0"
                  checked={seniorsOfAgeGreaterThenSixty === "0"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("0");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      seniorsOfAgeGreaterThenSixty: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="1"
                  checked={seniorsOfAgeGreaterThenSixty === "1"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("1");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      seniorsOfAgeGreaterThenSixty: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="2"
                  checked={seniorsOfAgeGreaterThenSixty === "2"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("2");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      seniorsOfAgeGreaterThenSixty: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="3"
                  checked={seniorsOfAgeGreaterThenSixty === "3"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("3");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      seniorsOfAgeGreaterThenSixty: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="4"
                  checked={seniorsOfAgeGreaterThenSixty === "4"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("4");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      seniorsOfAgeGreaterThenSixty: false,
                    }));
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="5"
                  checked={seniorsOfAgeGreaterThenSixty === "5"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("5");
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      seniorsOfAgeGreaterThenSixty: false,
                    }));
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>Please cut and paste below three (3) of the most outstanding comments/testimonials you have received from your clients in the past for the services you have provided to them.</span>
          </Col>
        </Row>
        <Row>
          {/* <Col md={4}>
            <span>
              <label
                htmlFor="fileUpload"
                style={{
                  cursor: "pointer",
                  color: "rgb(83, 83, 233)",
                  textDecoration: "underline",
                  marginLeft: "20px",
                }}
              >
                Attach client testimonials
              </label>
            </span>{" "}
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="fileUpload"
                className="file-upload-input"
                name="isoCertificate"
                style={{ display: "none" }}
                onChange={(event) => AttachTestimonial(event)}
                multiple
              />
            </div>
          </Col> */}
          <Col md={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
              >
                {testimonialfileUploading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  ></CircularProgress>
                ) : (
                  "Attach client testimonials"
                )}
                <input
                  type="file"
                  multiple
                  hidden
                  onChange={(event) => AttachTestimonial(event)}
                />
              </Button>
              {outstandingTestimonialsFileList.length > 0 && (
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setisTestimonialModalOpen(true)}
                  >
                    View Testimonials
                  </Button>
                  <ModalForFileView
                    open={isTestimonialModalOpen}
                    onClose={() => setisTestimonialModalOpen(false)}
                    data={outstandingTestimonialsFileList}
                    partnerType="PSYCHIATRIST"
                  />
                </div>
              )}
            </div>

            {selectedTestimonialFiles.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                >
                  Selected Files
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {selectedTestimonialFiles.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        backgroundColor: "#f9f9f9",
                        // maxWidth: "300px",
                        width: "100%",
                      }}
                    >
                      {/* File Name */}
                      <Typography variant="body2">{file.name}</Typography>
                    </div>
                  ))}
                </div>
              </Box>
            )}
          </Col>
        </Row>

        {testimonials?.length < 3 ? (
          <Row className="mt-2">
            <Col md={12}>
              <Label>If you would rather mention the testimonials, then do so in the space provided below. Just cut and paste up to three (3) testimonials. Click on “Add testimonial” below.</Label>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <br />
              <Label>
                <b>Testimonials : </b>
              </Label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4}>
            {testimonials?.length < 3 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={addTestimonial}
              >
                Add Testimonial
              </Button>
            )}
          </Col>
          <Row>
            {}
            {testimonials?.map((testimonial, index) => (
              <Col
                md={12}
                key={index}
              >
                <Input
                  id={`testimonial-${index}`}
                  name={`testimonial-${index}`}
                  value={testimonial}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Enter Testimonial"
                  type="textarea"
                  className="mt-2"
                  style={{
                    borderColor: "lightgrey",
                  }}
                />
              </Col>
            ))}
          </Row>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <h4>Reference</h4>
        <hr style={{ width: "10%", marginTop: "3px", height: "2px" }}></hr>
        <Row>
          <Col>
            <span
              style={{
                color: fieldErrors?.sharingReferences ? "red" : "inherit",
              }}
            >
              Would you be open to sharing 3 references of your past or current clients (1= children, 1= Female Adult and 1 = Male Adult) who can give us honest feedback of their experiences in
              working with you for your Psychiatrist services?
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                value="true"
                checked={sharingReferences === true}
                onChange={(e) => {
                  handlesharingReferences(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                value="false"
                checked={sharingReferences === false}
                onChange={(e) => {
                  handlesharingReferences(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        {(showReferences || sharingReferences) && (
          <>
            <Label>
              Please provide below their contact information and let them know that will be receiving a call from Living Scope Health Marketing team member. It will be a quick conversation only and we
              won’t take much of their time.
            </Label>
            <h5>Reference # 1</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference1", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                  style={{
                    borderColor: fieldErrors?.reference1Name ? "red" : "inherit",
                  }}
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate("reference1", "mobile", e.target.value);
                  }}
                  placeholder="Enter Mobile"
                  type="number"
                  maxLength={10}
                  className="mt-2"
                  style={{
                    borderColor: fieldErrors?.reference1Mobile ? "red" : "inherit",
                  }}
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 2</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference2", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate("reference2", "mobile", e.target.value);
                  }}
                  type="number"
                  maxLength={10}
                  placeholder="Enter Mobile"
                  className="mt-2"
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 3</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference3", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate("reference3", "mobile", e.target.value);
                  }}
                  placeholder="Enter Mobile"
                  type="number"
                  maxLength={10}
                  className="mt-2"
                />
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>

        <Row>
          <Col>
            <span
              style={{
                color: fieldErrors?.preferredDayAndTime ? "red" : "inherit",
              }}
            >
              Please check below your preferred time to provide consulting services to our clients. You will be later given a weekly calendar to manage your own preferred schedule on our Uncurl:
              health portal. Our clients will book your consultation based on your availability only.{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <FormGroup>
              <Label for="weekdays">Select Weekdays:</Label>
              <Row>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Sunday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Sunday"
                    value="Sunday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Monday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Monday"
                    value="Monday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Tuesday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Tuesday"
                    value="Tuesday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Wednesday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Wednesday"
                    value="Wednesday"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Thursday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Thursday"
                    value="Thursday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Friday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Friday"
                    value="Friday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes("Saturday")}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Saturday"
                    value="Saturday"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="time">Select Time:</Label>
                <Input
                  type="time"
                  name="time"
                  id="time"
                  value={preferredDayAndTime?.time}
                  onChange={(e) => {
                    handleTime("time", e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <Label>Please attach below copies of your credentials like Diplomas, course certificates, awards, newspaper cuttings etc. that adds to your credentials as a leading Psychiatrist.</Label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
              >
                {fileUploading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  ></CircularProgress>
                ) : (
                  "Attach Copies"
                )}
                <input
                  type="file"
                  multiple
                  hidden
                  onChange={(event) => handleAttachCopies(event)}
                />
              </Button>
              {documentsFileList.length > 0 && (
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsModalOpen(true)}
                  >
                    View Attached Documents
                  </Button>
                  <ModalForFileView
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    data={documentsFileList}
                    partnerType="PSYCHIATRIST"
                  />
                </div>
              )}
            </div>

            {selectedFiles.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                >
                  Selected Files
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        backgroundColor: "#f9f9f9",
                        // maxWidth: "300px",
                        width: "100%",
                      }}
                    >
                      {/* File Name */}
                      <Typography variant="body2">{file.name}</Typography>
                    </div>
                  ))}
                </div>
              </Box>
            )}
          </Col>
        </Row>
      </Form>
      <center>
        <Button
          onClick={handleSubmit}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(83, 83, 233)",
            color: "white",
          }}
        >
          Save
        </Button>
      </center>
    </>
  );
};

export default PsychiatristGeneralInfo;
