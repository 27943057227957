import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionStatusCode,
} from "amazon-chime-sdk-js";

import { createMeetingAndAttendee } from "./api";
import {
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { Mic, MicOff, CallEnd } from "@mui/icons-material";
import axios from "axios";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";

const logger = new ConsoleLogger("lsh_chime_logger", LogLevel.INFO);
const deviceController = new DefaultDeviceController(logger);

// main method
const Meeting = () => {
  const [MeetingSession, setMeetingSession] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [InstanceMediaRecorder, setInstaneMediaRecorder] = useState(null);
  const [soundClips, setSoundClips] = useState([]);
  const [chunks, setChunks] = useState([]);
  const [showDietitianCard, setshowDietitianCard] = useState("");

  const queryParams = new URLSearchParams(window.location.search);

  // Get and decode the "order" parameter
  const orderString = queryParams.get("order");
  const order = orderString
    ? JSON.parse(decodeURIComponent(orderString))
    : null;

  // Get and decode the "userType" parameter
  const userType = queryParams.get("userType");
  const token = queryParams.get("token") || sessionStorage.getItem("token");
  // Use the fetched data
  console.log("Order:", order);
  console.log("User Type:", userType);

  // join and start the meeting
  const joinMeeting = async () => {
    // e.preventDefault();
    const isEchoReductionEnabled = 1;
    const title = order.meetingId;
    const attendeeName =
      userType === "Patient" ? order.consumerName : order.dietitianName;
    const region =
      userType === "Patient" ? order.patientRegion : order.dietitianRegion;

    try {
      const { JoinInfo } = await createMeetingAndAttendee(
        title,
        attendeeName,
        region,
        isEchoReductionEnabled
      );
      console.log("JoinInfo: ", JoinInfo);
      console.log("Meeting: ", JoinInfo?.Meeting);
      console.log("Attendee: ", JoinInfo?.Attendee);

      const configuration = new MeetingSessionConfiguration(
        JoinInfo?.Meeting,
        JoinInfo?.Attendee
      );
      console.log("Meeting session created");

      // In the usage examples below, you will use this meetingSession object.
      const meetingSession = new DefaultMeetingSession(
        configuration,
        logger,
        deviceController
      );
      console.log("Meeting session configured");
      setMeetingSession(meetingSession);

      if (userType === "Patient") {
        const patientformData = {
          orderId: order.orderId,
          patientId: order.patientId,
          patientCallStatus: "Joined",
          callStatus: "Started",
        };
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}general/call-status-update`,
            patientformData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
        } catch (error) {
          console.error("Api error", error);
        }
        // AxiosInstance.post(`general/call-status-update`, patientformData)
        //   .then((response) => {})
        //   .catch((err) => {
        //     console.log("err: ", err);
        //   });
      } else {
        const dietitianformData = {
          orderId: order.orderId,
          patientCallStatus: "Joined",
          dietitianCallStatus: "Joined",
          callStatus: "Started",
        };
        AxiosInstance.post(`general/call-status-update`, dietitianformData)
          .then((response) => {})
          .catch((err) => {
            console.log("err: ", err);
          });
      }

      // Suppress devices
      /*meetingSession.audioVideo.setDeviceLabelTrigger(() => Promise.resolve(new MediaStream()));

			meetingSession.audioVideo.start();

			// Invoke devices
			meetingSession.audioVideo.setDeviceLabelTrigger(async () => 
				await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
			);*/
      const audioInputDevices =
        await meetingSession.audioVideo.listAudioInputDevices();

      console.log("audioInputDevices", audioInputDevices);

      var audioInputDeviceId = null;
      // An array of MediaDeviceInfo objects
      audioInputDevices.forEach((mediaDeviceInfo) => {
        if (mediaDeviceInfo.deviceId) {
          audioInputDeviceId = mediaDeviceInfo.deviceId;
        }
        //console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
      });
      console.log("Audio Input DeviceId: ", audioInputDeviceId);

      const audioOutputDevices =
        await meetingSession.audioVideo.listAudioOutputDevices();
      console.log("audioOutputDevices", audioOutputDevices);
      var audioOutputDeviceId = null;
      // An array of MediaDeviceInfo objects
      audioInputDevices.forEach((mediaDeviceInfo) => {
        if (mediaDeviceInfo.deviceId) {
          audioOutputDeviceId = mediaDeviceInfo.deviceId;
        }
        //console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
      });
      console.log("Audio Output DeviceId: ", audioOutputDeviceId);

      /*const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
            console.log('videoInputDevices', videoInputDevices);

			var videoInputDeviceId = null
			// An array of MediaDeviceInfo objects
			videoInputDevices.forEach(mediaDeviceInfo => {
				if (mediaDeviceInfo.deviceId) {
					videoInputDeviceId = mediaDeviceInfo.deviceId;
				}
				//console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
			});
			console.log('Video Input DeviceId: ', videoInputDeviceId);*/

      await meetingSession.audioVideo.startAudioInput(audioInputDeviceId);
      await meetingSession.audioVideo.chooseAudioOutput(audioOutputDeviceId);
      //await meetingSession.audioVideo.startVideoInput(videoInputDeviceId);

      // Stop video input. If the previously chosen camera has an LED light on,
      // it will turn off indicating the camera is no longer capturing.
      //await meetingSession.audioVideo.stopVideoInput();

      const audioElement = document.querySelector("audio");
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioElement.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing the microphone", error);
        });

      console.log("audioElement: ", audioElement);

      meetingSession.audioVideo.bindAudioElement(audioElement);

      /*const videoElement = document.querySelector("video");
			  navigator.mediaDevices
			  .getUserMedia({ video: true })
			  .then((stream) => {
			 	 videoElement.srcObject = stream;
			  })
			  .catch((error) => {
			  	console.error("Error accessing the camera", error);
			  });

			  meetingSession.audioVideo.bindVideoElement(videoElement);*/

      const observer = {
        audioVideoDidStart: () => {
          console.log("Started");
        },
      };

      meetingSession.audioVideo.addObserver(observer);

      meetingSession.audioVideo.start();

      startRecord();
      await meetingSession.start();
    } catch {
      console.error("Eror start meeting");
    }
  };

  // leaving the meeting
  const leaveMeeting = (e) => {
    e.preventDefault();
    const observer = {
      audioVideoDidStop: (sessionStatus) => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.Left) {
          //You called meetingSession.audioVideo.stop().

          console.log("You left the session");
          setMeetingSession(null);
        } else {
          console.log(
            "Stopped with a session status code: ",
            sessionStatusCode
          );
        }
      },
    };

    MeetingSession.audioVideo.addObserver(observer);

    MeetingSession.audioVideo.stop();
    stopRecord();
  };

  // Mute the mic
  //   const muteMic = () => {
  //     MeetingSession.audioVideo.realtimeMuteLocalAudio();
  //     const muted = MeetingSession.audioVideo.realtimeIsLocalAudioMuted();
  //     if (muted) {
  //       console.log("You are muted");
  //     } else {
  //       console.log("Other attendees can hear your audio");
  //     }
  //     setIsMuted(!isMuted);
  //   };

  //   // unmute the mic
  //   const unMuteMic = () => {
  //     const unmuted = MeetingSession.audioVideo.realtimeUnmuteLocalAudio();
  //     if (unmuted) {
  //       console.log("Other attendees can hear your audio");
  //     } else {
  //       // See the realtimeSetCanUnmuteLocalAudio use case below.
  //       console.log("You cannot unmute yourself");
  //     }
  //     setIsMuted(!isMuted);
  //   };
  const toggleMute = () => {
    if (isMuted) {
      MeetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      MeetingSession.audioVideo.realtimeMuteLocalAudio();
    }
    setIsMuted(!isMuted);
  };

  const startRecord = async (e) => {
    if (navigator.mediaDevices) {
      console.log("getUserMedia supported.");

      const constraints = { audio: true };
      //let chunks = [];
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // navigator.mediaDevices
      //   .getUserMedia(constraints)
      //   .then((stream) => {
      console.log("Stream active:", stream.active);
      console.log("Audio tracks:", stream.getAudioTracks());
      if (stream.getAudioTracks().length === 0) {
        console.error("No audio tracks found in the stream.");
      }
      const mediaRecorder = new MediaRecorder(stream);
      setInstaneMediaRecorder(mediaRecorder);
      console.log("Media Recoder ...", mediaRecorder);
      mediaRecorder.ondataavailable = (e) => {
        console.log("ondataavailable triggered");
        if (e.data.size > 0) {
          console.log("Chunk received:", e.data.size);
          setChunks((prevChunks) => [...prevChunks, e.data]);
        } else {
          console.warn("Empty chunk received.");
        }
      };
      mediaRecorder.start(1000);
      console.log("MediaRecorder started:", mediaRecorder.state);
      console.log("recorder started");

      // mediaRecorder.ondataavailable = (e) => {
      //   console.log("Chunk received:", e.data.size);
      //   // setChunks((prevChunks) => [...prevChunks, e.data]);
      //   if (e.data.size > 0) {
      //     setChunks((prevChunks) => [...prevChunks, e.data]);
      //   }
      //   //chunks.push(e.data);
      // };
      // })
      // .catch((err) => {
      //   console.error(`The following error occurred: ${err}`);
      // });
    }
  };

  const stopRecord = (e) => {
    InstanceMediaRecorder.stop();
    console.log(InstanceMediaRecorder.state);
    console.log("Recorder stopped.");

    InstanceMediaRecorder.onstop = () => {
      console.log("Last data to read (after MediaRecorder.stop() called).");

      //audio.controls = true;
      const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
      //const blob = new Blob(chunks, { type: InstanceMediaRecorder.mimeType });
      console.log("blob", blob);
      const audioURL = window.URL.createObjectURL(blob);
      console.log("Audio URL: ", audioURL);
      const formData = new FormData();
      const fileName = `audio_recording_${Date.now()}.ogg`;
      formData.append("orderId", order.orderId);
      formData.append("patientId", order.patientId);
      formData.append("status", "Call Completed");
      formData.append("file", blob, fileName);
      formData.append("fileType", order.typeOfCall);

      try {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}consultation/meeting-status-update`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
              },
            }
          )
          .then(() => window.close());
      } catch (response) {
        console.error("API Error:", response);
        window.close();
      }
      const newClip = {
        url: audioURL,
      };

      setSoundClips((prevClips) => [...prevClips, newClip]);
      setChunks([]); // Clear chunks
    };
  };

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const fetchCallStatusUntilNonNull = async (order, isMounted) => {
    const appointmentTimeEnd = new Date(
      `${order.appointmentDate}T${order.appointmentTimeEndTime}:00`
    ).getTime();
    let result = null;

    while (isMounted.current && result === null) {
      const now = new Date().getTime();

      // Stop polling if the current time exceeds the appointment end time
      if (now > appointmentTimeEnd) {
        console.log(`End time reached for orderId: ${order.orderId}`);
        return;
      }

      try {
        console.log(`Fetching call status for orderId: ${order.orderId}`);
        const response = await AxiosInstance.get(
          `consultation/get-call-status/${order.orderId}`
        );
        console.log("call status", response);

        // Assuming the relevant data is in `response.data`
        if (response) {
          result = response.dietitianCallStatus;
          setshowDietitianCard(response.dietitianCallStatus || "");
          console.log("Non-null response received:", result);
        } else {
          console.log("Response was null. Retrying...");
          await wait(2000); // Wait for 2 seconds before retrying
        }
      } catch (err) {
        console.error("Error fetching call status:", err);
        await wait(2000); // Wait before retrying on error
      }
    }

    return result;
  };

  useEffect(() => {
    // joinMeeting();
    const isMounted = { current: true };
    const now = new Date();
    if (userType === "Patient" && MeetingSession != null) {
      const appointmentDate = new Date(order.appointmentDate);
      const appointmentTimeEnd = new Date(
        `${order.appointmentDate}T${order.appointmentTimeEndTime}:00`
      ).getTime();

      // Check if the order's appointment date is today
      if (appointmentDate.toDateString() === now.toDateString()) {
        // If the appointment starts in less than 2 minutes
        if (now.getTime() < appointmentTimeEnd) {
          console.log(`Monitoring call status for orderId: ${order.orderId}`);
          fetchCallStatusUntilNonNull(order, isMounted);
        }
      }
    }
    return () => {
      console.log("Cleanup: Stopping all API polling.");
      isMounted.current = false;
    };
  }, [MeetingSession]);

  console.log("instane ", InstanceMediaRecorder);

  return (
    <>
      <div>
        <audio></audio>
      </div>
      {!MeetingSession && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "#f5f5f5",
            padding: 2,
          }}
        >
          <Card
            sx={{
              maxWidth: 600,
              width: "100%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 2,
                  color: "#3f51b5",
                }}
              >
                Appointment Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>
                      {order.partnerType === "physician"
                        ? "Physiotherapist"
                        : "Dietitian"}{" "}
                      Name:
                    </strong>{" "}
                    {order.dietitianName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Patient Name:</strong> {order.consumerName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Appointment Date:</strong> {order.appointmentDate}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Appointment Time:</strong>{" "}
                    {order.appointmentTimeStartTime} -{" "}
                    {order.appointmentTimeEndTime}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Type of Call:</strong> {order.typeOfCall}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Status:</strong> {order.statusForPatient}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Patient Address:</strong>{" "}
                    {order.consumerAddress.addressLine1},{" "}
                    {order.consumerAddress.city}, {order.consumerAddress.state},{" "}
                    {order.consumerAddress.country}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <strong>Meeting ID:</strong> {order.meetingId}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={joinMeeting}
                sx={{
                  paddingX: 4,
                  fontSize: "1rem",
                  textTransform: "none",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                Join Meeting
              </Button>
            </Box>
          </Card>
        </Box>
      )}
      {MeetingSession && (
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 5,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Meeting Started
          </Typography>
          {/* <Typography variant="subtitle1">{`Participant: ${
            userType === "Patient" ? order.consumerName : order.dietitianName
          }`}</Typography> */}
          {/* <audio style={{ display: "none" }}></audio> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              mt: 3,
              flexWrap: { xs: "wrap", md: "nowrap" }, // Wrap on small screens, row on larger screens
            }}
          >
            {/* Dietitian Card */}
            {(userType === "Patient" && showDietitianCard !== "") ||
            userType === "Dietitian" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: { xs: "100%", md: "500px" },
                  height: { xs: 150, md: 250 },
                  backgroundColor: "primary.light",
                  borderRadius: 2,
                  boxShadow: 3,
                  color: "primary.contrastText",
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase", // Ensure initials are always capitalized
                  }}
                >
                  {order.dietitianName
                    ? order.dietitianName
                        .split(" ")
                        .map((n) => n[0])
                        .join("")
                    : "D"}
                </Typography>
                <Typography variant="h6" mt={1}>
                  {order.partnerType === "physician"
                    ? "Physiotherapist"
                    : "Dietitian"}
                </Typography>
              </Box>
            ) : null}
            {/* Consumer Card */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "100%", md: "500px" },
                height: { xs: 150, md: 250 },
                backgroundColor: "secondary.light",
                borderRadius: 2,
                boxShadow: 3,
                color: "secondary.contrastText",
              }}
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase", // Ensure initials are always capitalized
                }}
              >
                {order.consumerName
                  ? order.consumerName
                      .split(" ")
                      .map((n) => n[0])
                      .join("")
                  : "C"}
              </Typography>
              <Typography variant="h6" mt={1}>
                Patient
              </Typography>
            </Box>
          </Box>

          {/* Mute and Leave Meeting Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mt: 3,
            }}
          >
            <IconButton
              color={isMuted ? "secondary" : "primary"}
              onClick={toggleMute}
              aria-label="Mute/Unmute"
              size="large"
            >
              {isMuted ? <MicOff fontSize="large" /> : <Mic fontSize="large" />}
            </IconButton>
            <Button
              variant="contained"
              color="error"
              onClick={leaveMeeting}
              startIcon={<CallEnd />}
              size="large"
            >
              Leave
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Meeting;
